 // Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)

import React, { useEffect, useState } from "react";
import {
    Descriptions,
    Breadcrumb,
    PageHeader,
    Tag,
    Empty,
    Timeline,
    Collapse,
    Popover,
  } from "antd";
  
import { Title, Wrapper } from "./detailStyle";
import { Content } from "antd/lib/layout/layout";
const { Panel } = Collapse;

  export const TicketToPrint = React.forwardRef((props, ref) => {
    
    const {data} = props;
    const makeLocalTime = (date) => {
        let hours = date.split("T")[1].split(":")[0];
        let minutes = date.split("T")[1].split(":")[1];
        var am = true;
        if (hours > 12) {
        am = false;
        hours -= 12;
        } else if (hours == 12) {
        am = false;
        } else if (hours == 0) {
        hours = 12;
        }
        return (
        date.split("T")[0] +
        "," +
        hours +
        ":" +
        minutes +
        " " +
        (am ? "A.M" : "P.M")
        );
    };

  
  const capitalizer = s => 
    s.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')
  
    return (
      data ? 
        <Wrapper ref={ref}>
          <h2>
            Ticket No : {data?.id}
          </h2>
          <h4>Open Date & Time: {data?.start_time ? makeLocalTime(data?.start_time) : null}</h4>
          {data?.end_time ?
            <h4>Close Date & Time: {makeLocalTime(data?.end_time)}</h4>
          : null}
         {data?.status === 'completed' ? 
          (
            <Tag color="#2ecc71">Solved</Tag>
          ) 
          : 
          (
            <Tag color="#3498db">{capitalizer(data?.status)}</Tag>
          )}
          <Tag color="#cd2df5">
            Priority: {data?.priority}
          </Tag>
          <br />
          <br />
          <Title>Basic Information</Title>
          <Descriptions size="middle">
            {data?.pop ?
              <>
                <Descriptions.Item label="Pop">
                  <strong>{data?.pop?.id} ({data?.pop?.name})</strong>
                </Descriptions.Item>
                <Descriptions.Item label="Router">
                  <strong>{data?.router?.id} ({data?.router?.name})</strong>
                </Descriptions.Item>
                <Descriptions.Item label="Switch">
                  <strong>{data?.switch?.id} ({data?.switch?.name})</strong>
                </Descriptions.Item>
                <Descriptions.Item label="Switch Port no.">
                  <strong>{data?.switch_port_no}</strong>
                </Descriptions.Item>
              </>
            :null}
            
            {data?.client ?
              <>
                <Descriptions.Item label="Client">
                  <strong>{data?.client?.name}</strong>
                </Descriptions.Item>
                <Descriptions.Item label="Branch">
                  <strong>{data?.sub_client?.name}</strong>
                </Descriptions.Item>
                <Descriptions.Item label="Contact Person">
                  <strong>{data?.contact_person}</strong>
                </Descriptions.Item>
                <Descriptions.Item label="Mobile">
                  <strong>{data?.mobile_number}</strong>
                </Descriptions.Item>
              </>
            : null}
            
            <Descriptions.Item label="Department">
              <strong>{capitalizer(data?.type)}</strong>
            </Descriptions.Item>
            <Descriptions.Item label="Problem Type">
              <strong>{data?.problem_type}</strong>
            </Descriptions.Item>
            <Descriptions.Item label="Subject">
              <strong>{data?.subject}</strong>
            </Descriptions.Item>
            <Descriptions.Item label="Created by">
              <strong>{data?.creator?.name}</strong>
            </Descriptions.Item>
            <Descriptions.Item label="">
              <strong></strong>
            </Descriptions.Item>
            <Descriptions.Item label="Problem Detail:">
              <strong>{data?.description}</strong>
            </Descriptions.Item>
          </Descriptions>
  
          <Title>Logs</Title>
          <br />
          {data?.logs ? (
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
              <tr>
                <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>Date Time</th>
                <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>Name</th>
                <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>Action</th>
                <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>Status</th>
              </tr>
              {data.logs.map((item, index) => (
                <tr>
                  <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{item?.created_at ? makeLocalTime(item?.created_at) : null}</td>
                  <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{item?.admin?.name}</td>
                  <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{item?.message}</td>
                  <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{item?.status}</td>
                </tr>
              ))}
  
            </table>
            // <Timeline mode="left">
            //   {data.logs.map((item, index) => (
            //     <Timeline.Item
            //       label={makeLocalTime(item?.created_at)}
            //       keys={index}
            //     >
            //       {item?.message}
            //     </Timeline.Item>
            //   ))}
            // </Timeline>
          ) : (
            <Empty />
          )}
          <br />
          <br />
        </Wrapper>
       : null
    );
  });