import React, { useState, useRef } from "react";
import {
  Input,
  Space,
  Empty,
  Timeline,
  Button,
  Table,
  Row,
  Col,
  Statistic,
} from "antd";
import { connect } from "react-redux";
import { Title, Wrapper } from "./detailStyle";
import { Content } from "antd/lib/layout/layout";
import ReactToPrint from 'react-to-print';
import { ClientToprint } from "./ClientToprint";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const Search = Input.Search;

const NewConnection = ({ data  }) => {
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const componentRef = useRef();

  const makeLocalTime = (date) => {
    let hours = date.split("T")[1].split(":")[0];
    let minutes = date.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours == 12) {
      am = false;
    } else if (hours == 0) {
      hours = 12;
    }
    return (
      date.split("T")[0] +
      "," +
      hours +
      ":" +
      minutes +
      " " +
      (am ? "A.M" : "P.M")
    );
  };

  const content = (item) => {
    return <div>
    <p style={{ margin: '2px' }}>Unit Data: {item?.unit_data}</p>
    <p style={{ margin: '2px' }}>Conatct Amount: {item?.contact_amount}</p>
    <p style={{ margin: '2px' }}>Rate Amount: {item?.rate_amount}</p>
    <p style={{ margin: '2px' }}>Vat Include: {item?.vat_include ? 'Vat included' : 'Not Included'}</p>
    <p style={{ margin: '2px' }}>Vat Amount: {item?.vat_amount}</p>
    <p style={{ margin: '2px' }}>Vat Rate: {item?.vat_rate}</p>
    <p style={{ margin: '2px' }}>Internate: {item?.internet}</p>
    <p style={{ margin: '2px' }}>Youtube: {item?.youtube}</p>
    <p style={{ margin: '2px' }}>Other: {item?.other}</p>
  </div>
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Search
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
      setSearchText('');
  };

  const capitalizer = s => 
    s.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')

  const createData = (name, branch, area, connection_type, unit_data, bdix, facebook, internet, youtube, other, installation_date) => {
    return {name, branch, area, connection_type, unit_data, bdix, facebook, internet, youtube, other, installation_date};
  };

  const columns = [
    {
      title: 'Client',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Branch Name',
      dataIndex: 'branch',
      key: 'branch',
      width: '20%',
      ...getColumnSearchProps('branch'),
    },
    {
      title: 'Area',
      dataIndex: 'area',
      key: 'area',
      width: '20%',
      ...getColumnSearchProps('area'),
    },
    {
      title: 'Connection Type',
      dataIndex: 'connection_type',
      key: 'connection_type',
      width: '20%',
      ...getColumnSearchProps('connection_type'),
    },
    {
      title: 'Unit Data',
      dataIndex: 'unit_data',
      key: 'unit_data',
      width: '20%',
      ...getColumnSearchProps('unit_data'),
    },
    {
      title: 'BDIX',
      dataIndex: 'bdix',
      key: 'bdix',
      width: '20%',
    },
    {
      title: 'Facebook',
      dataIndex: 'facebook',
      key: 'facebook',
      width: '20%',
    },
    {
      title: 'Internet',
      dataIndex: 'internet',
      key: 'internet',
      width: '20%',
    },
    {
      title: 'Youtube',
      dataIndex: 'youtube',
      key: 'youtube',
      width: '20%',
    },
    {
      title: 'Other',
      dataIndex: 'other',
      key: 'other',
      width: '20%',
    },
    {
      title: 'Installation Date',
      dataIndex: 'installation_date',
      key: 'installation_date',
      width: '20%',
    },
  ];

  const rows = [
    data
      ? data[0]?.list?.map((item, key) => createData(
        // key + 1, 
        item?.client, 
        item['branch name'],
        item?.area, 
        item['connection type'], 
        item['unit data'] + item['bandwidth unit'] , 
        item['bdix'] + item['bandwidth unit'] , 
        item['facebook'] + item['bandwidth unit'] , 
        item['internet'] + item['bandwidth unit'] , 
        item['youtube'] + item['bandwidth unit'] , 
        item['other'] + item['bandwidth unit'] , 
        item['installation date'],
      ))
      :
      []
  ];
    return (
    <Content style={{ margin: "0 16px", }}>
      {console.log(data)}
      <Row gutter={16}>
        <Col span={4}>
          <Statistic title="Total Unit Data (mbps)" value={data[0]?.total_unit_data} />
        </Col>
        <Col span={4}>
          <Statistic title="Total BDIX (mbps)" value={data[0]?.total_bdix} />
        </Col>
        <Col span={4}>
          <Statistic title="Total Internet (mbps)" value={data[0]?.total_internet} />
        </Col>
        <Col span={4}>
          <Statistic title="Total Youtube (mbps)" value={data[0]?.total_youtube} />
        </Col>
        <Col span={4}>
          <Statistic title="Total Facebook (mbps)" value={data[0]?.total_facebook} />
        </Col>
        <Col span={4}>
          <Statistic title="Total Other (mbps)" value={data[0]?.total_other} />
        </Col>
      </Row>
      <br />
      <Table size='small' columns={columns} pagination={false} dataSource={rows[0]} scroll={{ x: 1000, y:450 }} sticky/>
    </Content>
  );
};

export default connect(null, { })(
  NewConnection
);
