import React, { Component } from 'react'
//import 'antd/dist/antd.css';
import { 
  Table, Input, Button, Space, PageHeader, Popconfirm, Breadcrumb,Tag, 
  Drawer, Modal, Form, Pagination, Row, Col, Tooltip, Select, DatePicker,
} from 'antd';
import Highlighter from 'react-highlight-words';
import { CheckCircleOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, EyeOutlined, PlusCircleFilled, RollbackOutlined, SearchOutlined,SettingOutlined,StopOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { allTickets, ticketReportDownloader, reOpenTicket, ticketHelper, transmissionHelper, supportHelper, supportHelperById, } from "../../actions/support";
import { Link } from 'react-router-dom';
import history from '../../history';
import { connect } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { renderInput, renderSelect, renderTextField } from '../../component/form/form';
import { getPops } from '../../actions/pop';
import ReactTimeAgo from 'react-time-ago';
import TimeSince from './timeSince';
import moment from 'moment';

const { RangePicker } = DatePicker;
// import { renderTextField } from '../../../component/form/form';
// import Remarks from '../../../component/remark';
// import ModalForm from './modalForm';
// import EditModalForm from './editModalForm';
const Search = Input.Search;
const { Option } = Select;
const showTotal = (total) => `Total ${total} items`;
const AllTicket = ({ allTickets, ticketReportDownloader, reOpenTicket, ticketHelper, transmissionHelper, supportHelper, supportHelperById, getProcessing, todayData}) => {
  const [ID, setID] = useState('')
  const [TID, setTID] = useState('')
  const [data, setData] = useState(null)
  const [count, setCount] = useState(null)
  const [status, setStatus] = useState(null)
  const [department, setDepartment] = useState(null)
  const [pType, setPType] = useState(null)
  const [admin, setAdmin] = useState(null)
  const [client, setClient] = useState(null)
  const [branch, setBranch] = useState(null)
  const [pop, setPop] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [page, setPage] = useState(1)
  const [name, setName] = useState('')
  const [differ, setDiffer] = useState(0);
  const [allDept, setallDept] = useState(null)
  const [allAdmin, setallAdmin] = useState(null)
  const [allProblem, setallProblem] = useState(null)
  const [allBranch, setallBranch] = useState(null)
  const [allClient, setallClient] = useState(null)
  const [allPops, setallPops] = useState(null)
  // const [data, setData] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [flag, setFlag] = useState(false);
  const [access, setAccess] = useState('');
  const [form] = Form.useForm();
  const [cform] = Form.useForm();
  

  const createOption = (value, label,) => {
    return {value, label};
  };

  const getData = async () => {
    const res =  await allTickets( page, TID, status, department, pType, admin, client, branch, pop, startDate, endDate );
    setData(res?.ticket);
    setCount(res?.count)
    const helper =  await ticketHelper();
    let dept = [];
    dept = helper?.department?.map(item => (
      createOption( item?.name, item?.name)
    ))

    const supportH = await supportHelper();
    const transmissionH = await transmissionHelper();
    let cl = [];
    let pl = [];
    let al = [];
    let popl = [];
    cl = supportH?.client_list?.map(item => (
      item?.status === 'active' ?
        createOption(
          item?.name + ',' +  item?.id,
          item?.name,
        )
      :
      []
    ))
    pl = supportH?.problemList?.map(item => (
      createOption( item?.name, item?.name)
    ))
    al = supportH?.user?.map(item => (
      createOption( item?.user_id, item?.name)
    ))
    popl = transmissionH?.pop_list?.map(item => (
      createOption( item?.id, item?.name)
    ))
    setallDept(dept);
    setallClient(cl);
    setallProblem(pl);
    setallAdmin(al);
    setallPops(popl);
  }
  
  useEffect(() => {
    //window.scrollTo(0, 0);
    getData();
    // getProcessing();
    setAccess(JSON.parse(localStorage.getItem('access'))?.ticket_management);
  
  }, [])

  const showModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleOk = () => {
    setIsCreateModalOpen(false);
  };

  const handleCancel = () => {
    setIsCreateModalOpen(false);
  };

  const handleUpdate = async (id) => {
    setID(id)
    const res = await reOpenTicket(id)
    if(res) {
      handleApiReset()
    }
  }
  
  const handleView = async (id) => {
    history.push(`/ticket/details?id=${id}`);
        // const res =  await deleteRouter(item?.id, 'client-type');
    // if(res === 201){
    //   getProcessing();
    // };
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Search
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
      setSearchText('');
  };

  const createData = (id, type, clients, service, problem_type, date, down_time, status, action) => {
    return {id, type, clients, service, problem_type, date, down_time, status, action};
  };

  const makeLocalTime = (date) => {
    let mainDate = date.split('T')[0];
    let hours = date.split('T')[1].split(':')[0];
    let minutes = date.split('T')[1].split(':')[1];
    var am = true;
    if (hours > 12) {
        am = false;
        hours -= 12
    } else if (hours == 12) {
        am = false;
    } else if (hours == 0) {
        hours = 12
    }
    return mainDate + " , " + hours+":"+minutes + " " + (am ? "a.m" : "p.m")
  }

  const columns = [
    {
      title: 'Ticket no',
      dataIndex: 'id',
      key: 'id',
      width: '15%',
      ...getColumnSearchProps('id'),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: '20%',
      ...getColumnSearchProps('type'),
    },
    {
      title: 'Clients/POP',
      dataIndex: 'clients',
      key: 'clients',
      width: '25%',
    },
    {
      title: 'Branch',
      dataIndex: 'service',
      key: 'service',
      width: '25%',
    },
    {
      title: 'Problem type',
      dataIndex: 'problem_type',
      key: 'problem_type',
      width: '20%',
      ...getColumnSearchProps('problem_type'),
    },
    {
      title: 'Date Time',
      dataIndex: 'date',
      key: 'date',
      width: '20%',
    },
    {
      title: 'Down Since',
      dataIndex: 'down_time',
      key: 'down_time',
      width: '20%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '15%',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '20%',
    },
  ];

  const capitalizer = s => 
    s.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')
    
  const getDayDiff = (val) => {
    // const date1 = new Date();
    // const date2 = new Date(val);
    // const diffDays = parseInt((moment(date2).format('L') - moment(date1).format('L')) / (1000 * 60 * 60 * 24), 10); 
    // console.log(moment(date1).format('L'))
    // console.log(moment(date2).format('l'))
    // console.log(moment(moment(date2).format('L'), "YYYYMMDD").fromNow);
  }

    const formatTime = (date, status) => {
      const date2 = moment()
      const date1 = moment(date)
      const hDiff = date2.diff(date1, 'hours')  
      // setDiffer(hDiff) 
      const startDate = new Date(date).getTime();
      const now = new Date().getTime();
      const diff = now - startDate;
      let seconds = (Math.floor(diff / 1000));

      const yearInSeconds = 31536000;
      const monthInSeconds = 2592000;
      const dayInSeconds = 86400;
      const hourInSeconds = 3600;
      const minuteInSeconds = 60;
      const years = Math.floor(seconds / yearInSeconds);
      const months = Math.floor((seconds % yearInSeconds) / monthInSeconds);
      const days = Math.floor(((seconds % yearInSeconds) % monthInSeconds) / dayInSeconds);
      const hours = Math.floor((((seconds % yearInSeconds) % monthInSeconds) % dayInSeconds) / hourInSeconds);
      const minutes = Math.floor(((((seconds % yearInSeconds) % monthInSeconds) % dayInSeconds) % hourInSeconds) / minuteInSeconds);
      const remainingSeconds = (((seconds % yearInSeconds) % monthInSeconds) % dayInSeconds) % hourInSeconds % minuteInSeconds;
      if( years !== 0 && months !== 0 && days !== 0) return (
        status === 'completed' 
          ? 
            <p style={{ fontWeight: 'bold', color: 'rgba(6, 102, 75, 0.5)' }}>
              {years} years, {months} months, {days} days, {hours} hours, {minutes} minutes
            </p>
          :
            <p style={{ fontWeight: 'bold', color:'rgba(214, 48, 49,1.0)' }}>
              {years} years, {months} months, {days} days, {hours} hours, {minutes} minutes
            </p>
      );
      if( years == 0 && months !== 0 && days !== 0) return (
        status === 'completed' 
          ? 
            <p style={{ fontWeight: 'bold', color: 'rgba(6, 102, 75, 0.5)' }}>
              {months} months, {days} days, {hours} hours, {minutes} minutes
            </p>
          :
            <p style={{ fontWeight: 'bold', color:'rgba(214, 48, 49,1.0)' }}>
              {months} months, {days} days, {hours} hours, {minutes} minutes
            </p>
      );
      if( years == 0 && months !== 0 && days === 0) return (
        status === 'completed' 
          ? 
            <p style={{ fontWeight: 'bold', color: 'rgba(6, 102, 75, 0.5)' }}>
              {months} months, {days} days, {hours} hours, {minutes} minutes
            </p>
          :
            <p style={{ fontWeight: 'bold', color:'rgba(214, 48, 49,1.0)' }}>
              {months} months, {days} days, {hours} hours, {minutes} minutes
            </p>
      );
      else if( years == 0 && months == 0 && days !== 0) return (
        status === 'completed' 
          ? 
            <p style={{ fontWeight: 'bold', color: 'rgba(6, 102, 75, 0.5)' }}>
              {days} days, {hours} hours, {minutes} minutes
            </p>
          :
            <p style={{ fontWeight: 'bold', color:'rgba(214, 48, 49,1.0)' }}>
              {days} days, {hours} hours, {minutes} minutes
            </p>
      )
      else if( years == 0 && months == 0 && days == 0 && hours !== 0 ) return (
        status === 'completed' 
          ? 
            <p style={{ fontWeight: 'bold', color: 'rgba(6, 102, 75, 0.5)' }}>
              {hours} hours, {minutes} minutes
            </p>
          :
            <p style={{ fontWeight: 'bold', 
              color:
                (hDiff >= 3 && hDiff < 6) ? '#dba43d' :
                hDiff >= 6 ? 'rgba(214, 48, 49,1.0)' :
                null }}
            >
                  {hours} hours, {minutes} minutes
            </p>
      )
      
      else return `${minutes} minutes`;
    };

  const rows = [
    data
      ? data?.map((item, key) => createData(
        item?.id,
        capitalizer(item?.type),
        <>
        <p style={{ margin: '0px' }}>{item?.client?.name ? item?.client?.name : 'POP: ' + item?.pop?.name }</p>
        </>
        ,
        item?.sub_client?.name ? item?.sub_client?.name : 
        <>
          <p style={{ margin: '0px' }}>{item?.router?.name ? 'Router: ' + item?.router?.name : null}</p>
          <p style={{ margin: '0px' }}>{item?.switch?.name ? 'Switch: ' +item?.switch?.name : null}</p>
          <p style={{ margin: '0px' }}>{(item?.switch_port_no || item?.switch_port_no === 0) ? 'Switch Port: ' + item?.switch_port_no : null}</p>
        </>,
        item?.problem_type,
        item?.created_at ? makeLocalTime(item?.created_at) : null,
        item?.start_time ? formatTime(((item?.start_time).split('.')[0]), item?.status)
        // <TimeSince flag = {flag} status={item?.status} date={(item?.start_time).split('.')[0]} /> 
        : null,
        
        item?.status === 'completed' ? 
        (
          <Tag color="#2ecc71">Solved</Tag>
        ) 
        : 
        (
          <Tag color="#3498db">{capitalizer(item?.status)}</Tag>
        ),
        <>
        {access === 'a' ? 
          <>
          <Button size="small" type="primary" icon={<EyeOutlined  />} onClick={() => handleView(item?.id)}  title='View'></Button> &nbsp;
          
          {item?.status === 'completed' ? 
            <Button size="small" danger type="primary" onClick={() => handleUpdate(item?.id)}>Reopen</Button> 
          : null}
          &nbsp;
     </>
        : null}
        {access === 'w' ? 
          <>
          <Button size="small" type="primary" icon={<EyeOutlined  />} onClick={() => handleView(item?.id)} title='View'></Button> &nbsp;
          
          {item?.status === 'completed' ? 
            <Button size="small" danger type="primary" onClick={() => handleUpdate(item?.id)} title='Reopen'>Reopen</Button> 
          : null}
          &nbsp;

          </>
        : null}
        {access === 'r' ? 
          <>
          {item?.status === 'completed' ? 
            <Button size="small" danger type="primary" onClick={() => handleUpdate(item?.id)}>Reopen</Button>
          : null}
          </>
        : null}
        </>
        ))
      : "",
  ];

  const handlePagination = async (e) => {
    setPage(e);
    const res = await allTickets(e, TID, status, department, pType, admin, client, branch, pop, startDate, endDate )
    setData(res?.ticket);
    setCount(res?.count);
    setFlag(!flag);
  }

  const handleApiReset = async () => {
    setPage(1);
    setTID(null);
    setStatus(null);
    setDepartment(null);
    setPType(null);
    setAdmin(null);
    setClient(null);
    setBranch(null);
    setPop(null);
    setStartDate(null);
    setEndDate(null);
    const res = await allTickets(1, null, null, null, null, null, null, null, null, null, null )
    setData(res?.ticket);
    setCount(res?.count)
    setFlag(!flag);
  }

  const handleApiSearch = async () => {
    const res = await allTickets(page, TID, status, department, pType, admin, client, branch, pop, startDate, endDate)
    setData(res?.ticket);
    setCount(res?.count)
    setFlag(!flag);
  }
  

  const handleDownload = async () => {
    const res = await ticketReportDownloader(page, TID, status, department, pType, admin, client, branch, pop, startDate, endDate, true)
  }
  
  const handleServiceSelect = async (e) => {
    setClient(e);
    if(e){
      cform.setFieldsValue({
        sub_client: '',
      });
      const res =  await supportHelperById(e.split(',')[1]);
      if(res) {
        
      const services = [... 
        res?.sun_client_list?.map((item, key) => createOption(
          item?.id, 
          item?.name,
        ))
      ]
      setallBranch(services)
      }
      }
  };
  
  const tStatus = [
    {value:'active', label: 'Active'},
    {value: 'processing', label: 'Processing'},
    {value: 'half done', label: 'Half Done'},
    {value: 'completed', label: 'Completed'},
  ]


  const handleDate = (date) => {
    if (date) {
      const ds = new Date(date[0]._d);
      const de = new Date(date[1]._d);
      setStartDate(ds.toISOString());
      setEndDate(de.toISOString());
    } else {
      setStartDate('');
      setEndDate('');
      return;
    }
  };
  return ( 
    <Content style={{ margin: '0 16px' }}>

      <Breadcrumb style={{padding:"10px 0px"}}>
        <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>All Tickets</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="All Tickets"
        subTitle="Ticket Management"
        // extra={[
        //   <Button key="1"  type="primary" icon={<PlusCircleFilled />} >Create New
        //   </Button>,
        // ]}
      >
      </PageHeader>
      <Row gutter={20}>
        <Col xs={11} sm={11} md={4} lg={4} xl={4}>
            <Input name="id" value={TID} size="middle" placeholder="Ticket ID" allowClear style={{width:'99%'}} onChange={(e)=>setTID(e.target.value)} />
        </Col>
        
        <Col xs={11} sm={11} md={4} lg={4} xl={4}>
          <Select
            placeholder="Select Status"
            size="middle"
            allowClear
            style={{width:'99%'}}
            onChange={(e)=> setStatus(e)}
            value={status}
          >
            <Option value=""> None</Option>
            {tStatus?.map(item => (
              <Option value={item?.value}>{item?.label}</Option>
            ))}
          </Select>
        </Col>
        
        <Col xs={11} sm={11} md={4} lg={4} xl={4}>
          <Select
            showSearch
            style={{ width: "99%"}}
            placeholder="Select Department"
            optionFilterProp="children"
            onChange={(e) => setDepartment( e )}
            value={department}
          >
            <Option value="">
              None
            </Option>
            {allDept?.map(item => (
              <Option value={item?.value}>{item?.label}</Option>
            ))}
          </Select>
        </Col>
        
        <Col xs={11} sm={11} md={4} lg={4} xl={4}>
          <Select
            showSearch
            style={{ width: "99%"}}
            placeholder="Select Problem Type"
            name="ptype"
            optionFilterProp="children"
            onChange={(e) => setPType( e )}
            value={pType}
          >
            <Option value="">
              None
            </Option>
            {allProblem?.map(item => (
              <Option value={item?.value}>{item?.label}</Option>
            ))}
          </Select>
        </Col>
        
        <Col xs={11} sm={11} md={4} lg={4} xl={4}>
          <Select
            showSearch
            style={{ width: "99%"}}
            placeholder="Select Creator"
            name="creator"
            optionFilterProp="children"
            onChange={(e) => setAdmin( e )}
            value={admin}
          >
            <Option value="">
              None
            </Option>
            {allAdmin?.map(item => (
              <Option value={item?.value}>{item?.label}</Option>
            ))}
          </Select>
        </Col>
        
        <Col xs={11} sm={11} md={4} lg={4} xl={4}>
          <Select
            showSearch
            style={{ width: "99%"}}
            placeholder="Select Client"
            name="client"
            optionFilterProp="children"
            onChange={(e) => {setBranch(null); handleServiceSelect( e )}}
            value={client}
          >
            <Option value="">
              None
            </Option>
            {allClient?.map(item => (
              item.value ? <Option value={item.value}>{item.label}</Option> : null
            ))}
          </Select>
        </Col>
        
        <Col xs={24}>
          <br />
        </Col>

        <Col xs={11} sm={11} md={4} lg={4} xl={4}>
          <Select
            showSearch
            style={{ width: "99%"}}
            placeholder="Select Branch"
            name="branch"
            optionFilterProp="children"
            onChange={(e) => setBranch( e )}
            value={branch}
          >
            <Option value="">
              None
            </Option>
            {allBranch?.map(item => (
              <Option value={item?.value}>{item?.label}</Option>
            ))}
          </Select>
        </Col>
        
        <Col xs={11} sm={11} md={4} lg={4} xl={4}>
          <Select
            showSearch
            style={{ width: "99%"}}
            placeholder="Select Pop"
            name="pop"
            optionFilterProp="children"
            onChange={(e) => setPop( e )}
            value={pop}
          >
            <Option value="">
              None
            </Option>
            {allPops?.map(item => (
              <Option value={item?.value}>{item?.label}</Option>
            ))}
          </Select>
        </Col>
        
        <Col xs={11} sm={11} md={4} lg={4} xl={4}>
          <RangePicker
            name="date"
            onChange={handleDate}
            format="YYYY/MM/DD"
            // style={{ width: "100%", margin: "10px 0px" }}
          />
        </Col>
        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
            
            <Tooltip title="search">
              <Button style={{width:'99%'}} type="primary" onClick={handleApiSearch} icon={<SearchOutlined />} >Search </Button>
            </Tooltip>
        </Col>
        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
            
            <Tooltip title="search">
              <Button style={{width:'99%'}} type="primary" onClick={handleDownload} icon={<DownloadOutlined />} >Download </Button>
            </Tooltip>
        </Col>
        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
            
            <Tooltip title="search" onClick={handleApiReset}>
                <Button style={{width:'99%'}} type="primary" danger icon={<RollbackOutlined />}>Reset </Button>
            </Tooltip>
        </Col>
      </Row>
      <br />

      <Table size='small' columns={columns} pagination={false} dataSource={rows[0]} scroll={{ x: 1000, y: 450 }} sticky/>
      <br />

      <Pagination showTotal={showTotal} pageSizeOptions={[20]} current={page} pageSize="20" total={count} onChange={(e) => { handlePagination(e); }} />
      
    </Content>
   );
}
 
export default connect(null, {allTickets, ticketReportDownloader, reOpenTicket, ticketHelper, transmissionHelper, supportHelper, supportHelperById,})(AllTicket);