import React, { Component } from 'react'
//import 'antd/dist/antd.css';
import { Table, Input, Button, Space, PageHeader, Popconfirm, Breadcrumb,Tag, Drawer, Modal, Form, Pagination, Row, Col, Divider, Tooltip, Select    } from 'antd';
import Highlighter from 'react-highlight-words';
import { CheckCircleOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, EyeOutlined, PlusCircleFilled, RollbackOutlined, SearchOutlined,SettingOutlined,StopOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { getClients, clientReportDownloader, getClientDetails, createClient, updateClient, deleteClient, helper } from "../../actions/clients";
import { getBusinessType, getEmployee } from "../../actions/setting";

import { Link } from 'react-router-dom';
import history from '../../history';
import { connect } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { renderInput, renderSelect, renderTextField } from '../../component/form/form';
import ClientsDetails from './clientsDetails';
import Remarks from '../../component/remark';
const showTotal = (total) => `Total ${total} items`;
// import { renderTextField } from '../../../component/form/form';
// import Remarks from '../../../component/remark';
// import ModalForm from './modalForm';
// import EditModalForm from './editModalForm';
const Search = Input.Search;
const { Option } = Select;

const ClientList = ({ 
  getClients, clientReportDownloader, getClientDetails, updateClient, deleteClient, createClient, helper,
  getBusinessType, getEmployee, 
 }) => {
  const [ID, setID] = useState('')
  const [CID, setCID] = useState('')
  const [name, setName] = useState('')
  const [state, setState] = useState(null)
  const [details, setDetails] = useState(null)
  const [page, setPage] = useState(1)
  const [data, setData] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [deleteOption, setDeleteOption] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState('')
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [cform] = Form.useForm();
  const [businesstype, setBusinesstype] = useState([])
  const [employee, setEmployee] = useState([])
  const [district, setDistrict] = useState([])
  const [invoice, setInvoice] = useState([])
  const [access, setAccess] = useState([])

  const createOption = (value, label) => {
    return {value, label};
  };

  const getData = async () => {
    const res = await getClients(page, CID, name, state)
    setData(res);
    const option = await helper();

    const bt = option ? [... 
      option?.businessType?.map((item, key) => createOption(
      item, 
      item,
      ))
    ] : [];

    const emp = option ? [... 
      option?.employee?.map((item, key) => createOption(
      item, 
      item,
      ))
    ] : [];

    const dis = option ? [... 
      option?.district?.map((item, key) => createOption(
      item, 
      item,
      ))
    ] : [];
    const inv = option ? [... 
      option?.invoice?.map((item, key) => createOption(
      item, 
      item,
      ))
    ] : [];
    setBusinesstype(bt)
    setEmployee(emp)
    setDistrict(dis)
    setInvoice(inv)
    
  }

  useEffect(() => {
    //window.scrollTo(0, 0);
    setAccess(JSON.parse(localStorage.getItem('access'))?.client_management);
    getData();
  }, [])

  const handleRefresh = async () => {
    const res = await getClients(page, CID, name, state)
    setData(res);
  }

  const handleApiSearch = async () => {
    const res = await getClients(page, CID, name, state)
    setData(res);
  }

  const handleDownload = async () => {
    const res = await clientReportDownloader(page, CID, name, state, true)
    // setData(res);
  }

  const handleApiResetSearch = async () => {
    setPage('');
    setCID('');
    setName('');
    setState(null);
    const res = await getClients('', '', '', '');
    setData(res);
  }

  const showModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleOk = () => {
    setIsCreateModalOpen(false);
  };

  const handleCancel = () => {
    setIsCreateModalOpen(false);
  };

  const handleEdit = async (item) => {
    const res =  await getClientDetails(item?.id)
    setID(item?.id)
    setIsEditModalOpen(true);
    form.setFieldsValue({
      id: res?.id,
      name: res?.name,
      address: res?.address,
      district: res?.district,
      area: res?.area,
      description: res?.description,
      email: res?.email,
      bulk_email: res?.bulk_email,

      name1: res?.contact_person[0]?.name,
      number1: res?.contact_person[0]?.number,
      email1: res?.contact_person[0]?.email,
      description1: res?.contact_person[0]?.description,
      
      name2: res?.contact_person[1]?.name,
      number2: res?.contact_person[1]?.number,
      email2: res?.contact_person[1]?.email,
      description2: res?.contact_person[1]?.description,

      business_type: res?.business_type,
      bill_collection_by: res?.bill_collection_by,
      sale_by: res?.sale_by,
      vat_status: res?.vat_status,
      addition_amount: (res?.addition_amount).toString(),
      discount: res?.discount,
      web_address: res?.web_address,
      status: res?.status,
      billing_type: res?.billing_type,
      invoice_type: res?.invoice_type,
      remark: '',
    });
  }
  
  const handleView = async (item) => {
    const res =  await getClientDetails(item?.id)
    setDetails(res)
    setIsDetailModalOpen(true)
  }

  const handleDelete = async (item) => {
    setID(item?.id)
    setDeleteOption(true);
    // const res =  await deleteClient(item?.id);
    // if(res === 201){
    //   getData();
    // };
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Search
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
      setSearchText('');
  };

  const createData = (client_id, name, district, status, action) => {
    return {client_id, name, district, status, action};
  };

  const capitalizer = s => 
    s.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')
  
  const columns = [
    // {
    //   title: 'SL',
    //   dataIndex: 'id',
    //   key: 'id',
    //   width: '10%',
    // },
    {
      title: 'Client id',
      dataIndex: 'client_id',
      key: 'client_id',
      width: '20%',
      ...getColumnSearchProps('client_id'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'District',
      dataIndex: 'district',
      key: 'district',
      width: '20%',
      ...getColumnSearchProps('district'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '15%',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '30%',
    },
  ];
  
  const rows = [
    data
      ? data?.list?.map((item, key) => createData(
        // key + 1, 
        item?.id, 
        item?.name, 
        item?.district,
        
          item?.status == 'active' ? 
          (
            <Tag color="#6fcf20">Active</Tag>
          ) 
          : 
          item?.status == 'inactive' ? 
          (
            <Tag color="#e96710">Inactive</Tag>
          ) 
          : 
          item?.status == 'blocked' ? 
          (
            <Tag color="#e74c3c">Blocked</Tag>
          ) 
          : 
          (
            <Tag color="#f1c40f">Hold</Tag>
          ),
        
          // <Tag color='success'>Active</Tag>
          // : 
          // <Tag color='warning' >{capitalizer(item?.status)}</Tag>, 
        <>
        {access === 'a' ?
        <>
          <Button size="small" type="primary" icon={<EditOutlined  />} onClick={() => handleEdit(item)}>Edit</Button> &nbsp;
          <Button size="small" type="primary" icon={<EyeOutlined  />} onClick={() => handleView(item)}>View</Button> &nbsp;
          <Popconfirm
            title="Are you sure to delete this?"
            okText="Yes"
            cancelText="No"
            type="danger"
            onConfirm={() => handleDelete(item)}
          >
            <Button size="small" type="danger" icon={<DeleteOutlined  />} >Delete</Button> &nbsp;
          </Popconfirm>
          
        </>
        : null}
        {access === 'r' ?
        <>
          <Button size="small" type="primary" icon={<EyeOutlined  />} onClick={() => handleView(item)}>View</Button> &nbsp;
        </>
        : null}
        {access === 'w' ?
        <>
          <Button size="small" type="primary" icon={<EditOutlined  />} onClick={() => handleEdit(item)}>Edit</Button> &nbsp;
          <Button size="small" type="primary" icon={<EyeOutlined  />} onClick={() => handleView(item)}>View</Button> &nbsp;
          <Popconfirm
            title="Are you sure to delete this?"
            okText="Yes"
            cancelText="No"
            type="danger"
            onConfirm={() => handleDelete(item)}
          >
            <Button size="small" type="danger" icon={<DeleteOutlined  />} >Delete</Button> &nbsp;
          </Popconfirm>
          
        </>
        : null}
        </>
        ))
      : "",
  ];

  const onFinish = async (values) => {
    const res = await createClient(values, ID);
    if(res ===  201){
      getData();
      setIsCreateModalOpen(false);
      cform.resetFields();
    }
  };

  const onFinishEdit = async (values) => {
    const res = await updateClient(values, ID);
    if(res ===  201){
      getData();
      setIsEditModalOpen(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };
  
  const handlePagination = async (e) => {
    setPage(e);
    const res = await getClients(e)
    setData(res);
  }

  // const employee = [
  //   {value: 'ABC', label: 'ABC'},
  //   {value: 'DEF', label: 'DEF'},
  //   {value: 'GHI', label: 'GHI'},
  //   {value: 'JKL', label: 'JKL'},
  // ]

  const scheme = [
    {value: 'prepaid', label: 'Pre-paid'},
    {value: 'postpaid', label: 'Post-paid'},
  ]

  const status = [
    {value: 'active', label: 'Active'},
    {value: 'inactive', label: 'Inactive'},
    {value: 'blocked', label: 'Blocked'},
    {value: 'hold', label: 'Hold'},
  ]

  const clientType = [
    {value: 'Data Connectivity', label: 'Data Connectivity'},
    {value: 'Domain', label: 'Domain'},
    {value: 'Hosting', label: 'Hosting'},
  ]


  // const businesstype = [
  //   {value: 'Bagura', label: 'Bagura'},
  //   {value: 'Cumilla', label: 'Cumilla'},
  //   {value: 'Chadpur', label: 'Chadpur'},
  // ]

  const invoiceType = [
    {value: 'group', label: 'Group'},
    {value: 'individual', label: 'Individual'},
  ]

  const vatStatus = [
    {value: 'yes', label: 'Yes'},
    {value: 'no', label: 'No'},
  ]

  return ( 
    <Content style={{ margin: '0 16px' }}>

      <Breadcrumb style={{padding:"10px 0px"}}>
        <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Client List</Breadcrumb.Item>
      </Breadcrumb>

      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Client List"
        subTitle="Client Management"
        extra={[
          <Button key="1"  type="primary" icon={<PlusCircleFilled />} onClick = { showModal}>Create New
          </Button>,
        ]}
      >
      </PageHeader>

      <Row>
        <Col xs={10} sm={10} md={5} lg={5} xl={5}>
            <Input value={CID} size="middle" placeholder="Client ID" allowClear style={{width:'99%'}} onChange={(e)=>setCID(e.target.value)} />
        </Col>
        
        <Col xs={10} sm={10} md={5} lg={5} xl={5}>
            <Input value={name} size="middle" placeholder="Client Name" allowClear style={{width:'99%'}} onChange={(e)=> setName(e.target.value)} />
        </Col>

        <Col xs={10} sm={10} md={5} lg={5} xl={5}>
          <Select
            placeholder="Select Status"
            size="middle"
            allowClear
            style={{width:'99%'}} onChange={(e)=> setState(e)}
            value={state}
          >
              <Option value=''>None</Option>
              {status.map(item => (
                  <Option value={item.value}>{item.label}</Option>
              ))}
          </Select>
        </Col>
        <br />
        <br />
        
        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
            <Tooltip title="search" onClick={handleApiSearch}>
              <Button type="primary" icon={<SearchOutlined />} >Search </Button>
            </Tooltip>
        </Col>
        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
            
            <Tooltip title="search" onClick={handleDownload}>
                <Button type="primary" icon={<DownloadOutlined />}>Download </Button>
            </Tooltip>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
            
            <Tooltip title="search" onClick={handleApiResetSearch}>
                <Button type="primary" danger icon={<RollbackOutlined />}>Reset </Button>
            </Tooltip>
        </Col>
      </Row>
      <br />

      <Table size="small" columns={columns} pagination={false} dataSource={rows[0]} scroll={{ x: 1000 ,y: 400 }} sticky/>
      <br />

      <Pagination showTotal={showTotal} pageSizeOptions={[20]} current={page} pageSize="20" total={data?.count} onChange={(e) => { handlePagination(e); }} />
      
      <Modal 
        title="Add Client" 
        open={isCreateModalOpen} 
        onOk={handleOk} 
        onCancel={handleCancel} 
        width={1000}
        footer={null}
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={cform}
        >
          <Row gutter={0}>
            <Col xs={12}>
              
              {renderInput('id', 'Client ID', [
                { required: true, message: 'This field is required!' },
              ], 'small')
              }
              {renderInput('name', 'Clients Name', [
                  { required: true, message: 'This field is required!' },
                ], 'small')
              }
              {renderTextField('address', 'Address', [
                  // { required: true, message: 'This field is required!' },
                ], 'small')
              }
              {renderSelect('district', 'District', [
                  // { required: true, message: 'This field is required!' },
                ], "", district, 'single', 'small')
              }
              {renderInput('area', 'Area', [
                  // { required: true, message: 'This field is required!' },
                ], 'small')
              }
              {renderTextField('description', 'Description', [
                  // { required: true, message: 'This field is required!' },
                ], 'large')
              }
              {renderTextField('bulk_email', 'Bulk Eamil', [
                ], 'small')
              }
              {renderInput('email', 'Eamil Address', 
              [{ pattern: new RegExp(/\S+@\S+\.\S+/),
                 message: "Email is not valid", 
                  // { required: true, message: 'This field is required!' },
              }], 'small')
              }
            
            </Col>
            <Col xs={12}>
              {renderSelect('business_type', 'Business Type', [
                  // { required: true, message: 'This field is required!' },
                ], "", businesstype, 'single', 'small')
              }
              {renderSelect('bill_collection_by', 'Bill Collected By', [
                  // { required: true, message: 'This field is required!' },
                ], "", employee, 'single', 'small')
              }
              {renderSelect('sale_by', 'Sale By', [
                  // { required: true, message: 'This field is required!' },
                ], "", employee, 'single', 'small')
              }
              {renderSelect('vat_status', 'VAT Status', [
                  // { required: true, message: 'This field is required!' },
                ], "", vatStatus, 'single', 'small')
              }
              {renderInput('addition_amount', 'Addition Amount', [
                  // { required: true, message: 'This field is required!' },
                ], 'small', 'number')
              }
              {renderInput('discount', 'Discount', [
                  // { required: true, message: 'This field is required!' },
                ], 'small', 'number')
              }
              {renderInput('web_address', 'Web Address', [
                  // { required: true, message: 'This field is required!' },
                ], 'small')
              }
              {renderSelect('status', 'Client Status', [
                  { required: true, message: 'This field is required!' },
                ], "", status, 'single', 'small')
              }
              {renderSelect('billing_type', 'Billing Type', [
                  // { required: true, message: 'This field is required!' },
                ], "", scheme, 'single', 'small')
              }
              {renderSelect('invoice_type', 'Invoice Type', [
                  // { required: true, message: 'This field is required!' },
                ], "", invoice, 'single', 'small')
              }
              
            </Col>
            
            <Col xs={12}>
              <Divider>Contact Person 1</Divider>
              {renderInput('name1', 'Name', [
                  // { required: true, message: 'This field is required!' },
                ], 'small')
              }
              {renderInput('number1', 'Phone', [
                  // { required: true, message: 'This field is required!' },
                ], 'small')
              }
              {renderInput('email1', 'Eamil Address', [
                { pattern: new RegExp(/\S+@\S+\.\S+/),
                  message: "Email is not valid",
                }
                  // { required: true, message: 'This field is required!' },
                ], 'small')
              }
              {renderTextField('description1', 'Description', [
                  // { required: false, message: 'This field is required!' },
                ], 'small')
              } 
            </Col>
            <Col xs={12}>
              <Divider>Contact Person 2</Divider>
              {renderInput('name2', 'Name', [
                  // { required: true, message: 'This field is required!' },
                ], 'small')
              }
              {renderInput('number2', 'Phone', [
                  // { required: true, message: 'This field is required!' },
                ], 'small')
              }
              {renderInput('email2', 'Eamil Address', [
                { pattern: new RegExp(/\S+@\S+\.\S+/),
                message: "Email is not valid",
                }
                  // { required: true, message: 'This field is required!' },
                ], 'small')
              }
              {renderTextField('description2', 'Description', [
                  // { required: false, message: 'This field is required!' },
                ], 'small')
              }
              </Col>
          </Row>
           
          <Form.Item {...tailLayout}> 
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
                
        </Form>
      </Modal>
      
      <Modal 
        title="Edit Client" 
        open={isEditModalOpen} 
        onOk={() => setIsEditModalOpen(false)} 
        onCancel={() => setIsEditModalOpen(false)} 
        width={1000}
        footer={null}
        form={form}
      >
        <Form
          {...layout}
          name="Edit "
          initialValues={{ remember: true }}
          onFinish={onFinishEdit}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          
          <Row gutter={0}>
            <Col xs={12}>
              <Form.Item
                  label="Client ID"
                  name="id"
                  rules={[{ required: true, message: 'This field is required!' }]}
                  
                  >
                  <Input size="small" name="id"disabled />
              </Form.Item>
              {renderInput('name', 'Clients Name', [
                  { required: true, message: 'This field is required!' },
                ], 'small')
              }
              {renderTextField('address', 'Address', [
                  { required: false, message: 'This field is required!' },
                ], 'small')
              }
              {renderSelect('district', 'District', [
                  { required: false, message: 'This field is required!' },
                ], "", district, 'single', 'small')
              }
              {renderInput('area', 'Area', [
                  { required: false, message: 'This field is required!' },
                ], 'small')
              }
              {renderTextField('description', 'Description', [
                  { required: false, message: 'This field is required!' },
                ], 'large')
              }
              {renderTextField('bulk_email', 'Bulk Email', [
                  { required: false, message: 'This field is required!' },
                ], 'large')
              }
              {renderInput('email', 'Eamil Address', 
                [
                  { required: false, message: 'This field is required!' },
                  { pattern: new RegExp(/\S+@\S+\.\S+/),
                    message: "Email is not valid",
                  }
              ], 'small')
              }
            </Col>
            <Col xs={12}>
              {/* {renderSelect('client_type', 'Client type', [
                  { required: true, message: 'This field is required!' },
                ], "", clientType, 'single', 'small')
              }
              {renderSelect('invoice_type', 'Invoice Type', [
                  { required: true, message: 'This field is required!' },
                ], "", invoiceType, 'single', 'small')
              } */}
              

              {renderSelect('business_type', 'Business Type', [
                  { required: false, message: 'This field is required!' },
                ], "", businesstype, 'single', 'small')
              }
              {renderSelect('bill_collection_by', 'Bill Collection By', [
                  { required: false, message: 'This field is required!' },
                ], "", employee, 'single', 'small')
              }
              {renderSelect('sale_by', 'Sale By', [
                  { required: false, message: 'This field is required!' },
                ], "", employee, 'single', 'small')
              }
              {renderSelect('vat_status', 'VAT Status', [
                  { required: false, message: 'This field is required!' },
                ], "", vatStatus, 'single', 'small')
              }
              {renderInput('addition_amount', 'Addition Amount', [
                  { required: false, message: 'This field is required!' },
                ], 'small', 'number')
              }
              {renderInput('discount', 'Discount', [
                  { required: false, message: 'This field is required!' },
                ], 'small', 'number')
              }
              {renderInput('web_address', 'Web Address', [
                  { required: false, message: 'This field is required!' },
                ], 'small')
              }
              {renderSelect('status', 'Client Status', [
                  { required: true, message: 'This field is required!' },
                ], "", status, 'single', 'small')
              }
              {renderSelect('billing_type', 'Billing Type', [
                  { required: false, message: 'This field is required!' },
                ], "", scheme, 'single', 'small')
              }
              {renderSelect('invoice_type', 'Invoice Type', [
                  { required: false, message: 'This field is required!' },
                ], "", invoice, 'single', 'small')
              }
            </Col>
            
            <Col xs={12}>
              <Divider>Contact Person 1</Divider>
              {renderInput('name1', 'Name', [
                  // { required: true, message: 'This field is required!' },
                ], 'small')
              }
              {renderInput('number1', 'Phone', [
                  // { required: true, message: 'This field is required!' },
                ], 'small')
              }
              {renderInput('email1', 'Eamil Address', [
                { pattern: new RegExp(/\S+@\S+\.\S+/),
                  message: "Email is not valid",
                }
                  // { required: true, message: 'This field is required!' },
                ], 'small')
              }
              {renderTextField('description1', 'Description', [
                  // { required: false, message: 'This field is required!' },
                ], 'small')
              } 
            </Col>
            <Col xs={12}>
              <Divider>Contact Person 2</Divider>
              {renderInput('name2', 'Name', [
                  // { required: true, message: 'This field is required!' },
                ], 'small')
              }
              {renderInput('number2', 'Phone', [
                  // { required: true, message: 'This field is required!' },
                ], 'small')
              }
              {renderInput('email2', 'Eamil Address', [
                { pattern: new RegExp(/\S+@\S+\.\S+/),
                message: "Email is not valid",
                }
                  // { required: true, message: 'This field is required!' },
                ], 'small')
              }
              {renderTextField('description2', 'Description', [
                  // { required: false, message: 'This field is required!' },
                ], 'small')
              }
              </Col>
            <Col xs={24}>
              {renderTextField('remark', 'Remark', [
                  { required: true, message: 'This field is required!' },
                ], 'small')
              } 
            </Col>
          </Row>
          <Form.Item {...tailLayout}> 
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
                
        </Form>
      </Modal>
      
      {
        details ?
        <Modal 
          title="Client Details" 
          open={isDetailModalOpen} 
          onOk={() => setIsDetailModalOpen(false)} 
          onCancel={() => setIsDetailModalOpen(false)} 
          width={1200}
          footer={null}
        >
          <ClientsDetails data={details} />
        </Modal>
        :
        null
      }
      {deleteOption ?
      
      <Remarks
        title=" "
        btn="Submit"
        placement="bottom"
        visible={deleteOption}
        fnc={() => setDeleteOption(false)}
        id={ID}
        delFnc={deleteClient}
        refresh={handleRefresh}
      />
      :null}
    </Content>
   );
}
 
export default connect(null, {getClients, clientReportDownloader, getClientDetails, createClient, updateClient, deleteClient, getBusinessType, getEmployee, helper})(ClientList);