 // Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)

import React, { useEffect, useState } from "react";
import {
    Descriptions,
    Breadcrumb,
    PageHeader,
    Tag,
    Empty,
    Timeline,
    Collapse,
    Popover,
  } from "antd";
  
import { Title, Wrapper } from "./detailStyle";
import { Content } from "antd/lib/layout/layout";
const { Panel } = Collapse;

  export const ServiceToprint = React.forwardRef((props, ref) => {
    
    const {data} = props;
    const makeLocalTime = (date) => {
        let hours = date.split("T")[1].split(":")[0];
        let minutes = date.split("T")[1].split(":")[1];
        var am = true;
        if (hours > 12) {
        am = false;
        hours -= 12;
        } else if (hours == 12) {
        am = false;
        } else if (hours == 0) {
        hours = 12;
        }
        return (
        date.split("T")[0] +
        "," +
        hours +
        ":" +
        minutes +
        " " +
        (am ? "A.M" : "P.M")
        );
    };

  
  const capitalizer = s => 
    s.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')
  
    return (
        <Wrapper ref={ref}>
          <h2>
            Client Name: {data?.client?.name}
          </h2>
          <h3>
            Branch Name: {data?.name}
          </h3>
          <h3>
            Connection Type: <strong>{data?.connection_type}</strong>
          </h3>
          {
            data?.status == 'active' ? 
            (
              <Tag color="#6fcf20">Active</Tag>
            ) 
            : 
            data?.status == 'inactive' ? 
            (
              <Tag color="#e96710">Inactive</Tag>
            ) 
            : 
            data?.status == 'blocked' ? 
            (
              <Tag color="#e74c3c">Blocked</Tag>
            ) 
            : 
            (
              <Tag color="#f1c40f">Temporary close</Tag>
            )
          }
  
          <Tag color="#cd2df5">
            {data?.created_at ? makeLocalTime(data?.created_at) : null}
          </Tag>
          <br />
          <br />
          {data ?
          <>
            <Collapse accordion defaultActiveKey={1}>
              <Panel header="General Information" key="1">
                <Descriptions>
                  <Descriptions.Item label="ID">
                    <strong>{data?.id}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="BRANCH NAME">
                    <strong>{data?.name}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="CLIENT ID">
                    <strong>{data?.client?.id}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="CLIENT NAME">
                    <strong>{data?.client?.name}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="ADDRESS">
                    <strong>{data?.address}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="DISTRICT">
                    <strong>{data?.district}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="AREA">
                    <strong>{data?.area}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="EMAIL">
                    <strong>{data?.email}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="BULK EMAIL">
                    <strong>{data?.bulk_email}</strong>
                  </Descriptions.Item>
                </Descriptions>
                {data?.contact_person?.map((item, key) => (
                  <>
                  <h3>Contact Person {key + 1}</h3>
                  <hr />
                  <Descriptions>
                  <Descriptions.Item label="NAME">
                    <strong>{item?.name}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="EMAIL">
                    <strong>{item?.email}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="PHONE">
                    <strong>{item?.number}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="DESCRIPTION">
                    <strong>{item?.description}</strong>
                  </Descriptions.Item>
                  </Descriptions>
                  </>
                ))}
              </Panel>
            </Collapse>
  
            <Collapse accordion defaultActiveKey={2}>
              <Panel header="Bank Information" key="2">
                <Descriptions>
                  <Descriptions.Item label="BRANCH NAME">
                    <strong>{data?.bank_info?.branch_name}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="BRANCH CODE">
                    <strong>{data?.bank_info?.branch_code}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="ATM NAME">
                    <strong>{data?.bank_info?.atm_name}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="ATM NO.">
                    <strong>{data?.bank_info?.atm_no}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="SERVICE IN">
                    <strong>{data?.bank_info?.service_in}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="CARRIER">
                    <strong>{data?.bank_info?.carrier}</strong>
                  </Descriptions.Item> 
                </Descriptions>
              </Panel>
            </Collapse>
  
            <Collapse accordion defaultActiveKey={3}>
  
              <Panel header="Connection Information" key="3">
                <Descriptions>
                  <Descriptions.Item label="CONNECTION TYPE">
                    <Tag color="#108ee9"><strong>{data?.connection_type}</strong></Tag>
                  </Descriptions.Item>
                  </Descriptions>
                <Descriptions>
                  <Descriptions.Item label="MEDIA">
                    <strong>{data?.bank_info?.media}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="CONNECTION DESCRIPTION">
                    <strong>{data?.connection_description}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="POP">
                    <strong>{data?.pop?.name}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="ROUTER">
                    <strong>{data?.router?.name} ( {data?.router?.id} )</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="ROUTER PORT">
                    <strong>{data?.microtik_port}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="SWITCH">
                    <strong>{data?.switch?.name} ( {data?.switch?.id} )</strong>
                  </Descriptions.Item> 
                  <Descriptions.Item label="SWITCH PORT NO.">
                    <strong>{data?.switch_port_no}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="VLANID">
                    <strong>{data?.vlanid}</strong>
                  </Descriptions.Item>
                  
                  <Descriptions.Item label="IP BLOCK">
                    <strong>{data?.ip_block}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="NATED FROM IP">
                    <strong>{data?.nated_from_ip}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="RADIO IP">
                    <strong>{data?.radio_ip}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="LINK FROM">
                    <strong>{data?.link_from}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="LINK TO">
                    <strong>{data?.link_to}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="MRTG">
                    <strong>{data?.mrtg}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="SALES BY">
                    <strong>{data?.sales_by}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="INST. DATE">
                    <strong>{(data?.inst_date !== null) ? makeLocalTime(data?.inst_date) : null}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="END DATE">
                    <strong>{(data?.end_date != '1971-12-16T00:00:00.000Z' && data?.end_date !== null) ? makeLocalTime(data?.end_date) : null}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="DESCRIPTION">
                    <strong>{data?.description}</strong>
                  </Descriptions.Item>
                </Descriptions>
              </Panel>
            </Collapse>
  
            <Collapse accordion defaultActiveKey={4}>
  
              <Panel header="Bandwidth Information" key="4">
                <Descriptions>
                  <Descriptions.Item label="UNIT DATA">
                    <strong>{data?.unit_data} {data?.unit_data_band}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="INTERNATE">
                    <strong>{data?.internet} {data?.internet_band}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="YOUTUBE">
                    <strong>{data?.youtube} {data?.youtube_band}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="FACEBOOK">
                    <strong>{data?.facebook} {data?.facebook_band}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="BDIX">
                    <strong>{data?.bdix} {data?.bdix_band}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="OTHER">
                    <strong>{data?.other} {data?.other_band}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="UNIT QUANTITY">
                    <strong>{data?.unit_quantity}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="CONTACT AMOUNT">
                    <strong>{data?.contact_amount}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="VAT INCLUDE">
                    <strong>{data?.vat_include ? 'YES' : 'NO'}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="VST RATE">
                    <strong>{data?.vat_rate}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="RATE AMOUNT">
                    <strong>{data?.rate_amount}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="VAT AMOUNT">
                    <strong>{data?.vat_amount}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="BILL START DATE">
                    <strong>{(data?.bill_start_date != '1971-12-16T00:00:00.000Z' && data?.bill_start_date !== null) ? makeLocalTime(data?.bill_start_date) : ''}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="RATE EFFECT DATE">
                    <strong>{(data?.rate_effect_date != '1971-12-16T00:00:00.000Z' && data?.rate_effect_date !== null) ? makeLocalTime(data?.rate_effect_date) : ''}</strong>
                  </Descriptions.Item>
                </Descriptions> 
              </Panel>
            </Collapse>
  
            <Collapse accordion defaultActiveKey={5}>
  
              <Panel header="Bandwidth Logs" key="5">
                {data?.bandwidth_logs ? (
                  <div style={{ height: '300px', overflow: 'scroll', fontSize: '12px' }}>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                      <tr>
                        <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>Pre-Internet</th>
                        <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>Pre-Facebook</th>
                        <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>Pre-Youtube</th>
                        <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>Pre-BDIX</th>
                        <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>Pre-Unit</th>
                        <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>Pre-Quantity</th>
                        <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>Pre-Rate</th>
                        <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>Pre-VAT</th>
                        <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>Change Time</th>
                        <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}></th>
                        <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>Change Internet</th>
                        <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>Change Facebook</th>
                        <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>Change Youtube</th>
                        <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>Change BDIX</th>
                        <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>Change Unit</th>
                        <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>Change Qty</th>
                        <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>Change Rate</th>
                        <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>Change Vat</th>
                      </tr>
                      {data.bandwidth_logs?.length === 1 ?
                      <tr>
                      <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>--</td>
                      <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>--</td>
                      <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>--</td>
                      <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>--</td>
                      <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>--</td>
                      <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>--</td>
                      <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>--</td>
                      <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>--</td>
                      <td colSpan={2} style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>--</td>
                      <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{data.bandwidth_logs[0]?.internet} {data.bandwidth_logs[0]?.internet_band}</td>
                      <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{data.bandwidth_logs[0]?.facebook} {data.bandwidth_logs[0]?.facebook_band}</td>
                      <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{data.bandwidth_logs[0]?.youtube} {data.bandwidth_logs[0]?.youtube_band}</td>
                      <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{data.bandwidth_logs[0]?.bdix} {data.bandwidth_logs[0]?.bdix_band}</td>
                      <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{data.bandwidth_logs[0]?.unit_data} {data.bandwidth_logs[0]?.unit_data_band}</td>
                      <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{data.bandwidth_logs[0]?.unit_quantity}</td>
                      <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{data.bandwidth_logs[0]?.rate_amount}</td>
                      <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{data.bandwidth_logs[0]?.vat_rate}</td>
                      </tr>
                      :
                      data.bandwidth_logs.map((item, index) => {
                        if(data.bandwidth_logs?.length !== index+1)
                        return <tr>
                          <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{item?.internet} {item?.internet_band}</td>
                          <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{item?.facebook}{item?.facebook_band}</td>
                          <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{item?.youtube}{item?.youtube_band}</td>
                          <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{item?.bdix}{item?.bdix_band}</td>
                          <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{item?.unit_data}{item?.unit_data_band}</td>
                          <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{item?.unit_quantity}</td>
                          <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{item?.rate_amount}</td>
                          <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{item?.vat_rate}</td>
                          <td colSpan={2} style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd', }}>{(data.bandwidth_logs[index + 1]?.rate_effect_date !== '1971-12-16T00:00:00.000Z' && data.bandwidth_logs[index + 1]?.rate_effect_date !== null) ? makeLocalTime(data.bandwidth_logs[index + 1]?.rate_effect_date)?.split(',')[0] : '--'}</td>
                          <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{data.bandwidth_logs[index + 1]?.internet}{data.bandwidth_logs[index + 1]?.internet_band}</td>
                          <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{data.bandwidth_logs[index + 1]?.facebook}{data.bandwidth_logs[index + 1]?.facebook_band}</td>
                          <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{data.bandwidth_logs[index + 1]?.youtube}{data.bandwidth_logs[index + 1]?.youtube_band}</td>
                          <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{data.bandwidth_logs[index + 1]?.bdix}{data.bandwidth_logs[index + 1]?.bdix_band}</td>
                          <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{data.bandwidth_logs[index + 1]?.unit_data}{data.bandwidth_logs[index + 1]?.unit_data_band}</td>
                          <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{data.bandwidth_logs[index + 1]?.unit_quantity}</td>
                          <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{data.bandwidth_logs[index + 1]?.rate_amount}</td>
                          <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{data.bandwidth_logs[index + 1]?.vat_rate}</td>
                          {/* <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>
                            {data.bandwidth_logs[index + 1]?.unit_data ? data.bandwidth_logs[index + 1]?.unit_data : data?.unit_data}
                          </td>
                          <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>
                            {data.bandwidth_logs[index + 1]?.unit_quantity ? data.bandwidth_logs[index + 1]?.unit_quantity : data?.unit_quantity}
                          </td>
                          <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>
                            {data.bandwidth_logs[index + 1]?.rate_amount ? data.bandwidth_logs[index + 1]?.rate_amount : data?.rate_amount}
                          </td>
                          <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>
                            {data.bandwidth_logs[index + 1]?.vat_rate ? data.bandwidth_logs[index + 1]?.vat_rate : data?.vat_rate}
                          </td> */}
                        </tr>
                        })}
  
                    </table>
                  </div>
                ) : (
                  <Empty />
                )}
              </Panel>
            </Collapse>
  
            <Collapse accordion defaultActiveKey={6}>
  
              <Panel header="General Logs" key="6">  
                {data?.logs ? (
                  <div style={{height: '300px', overflowY: 'scroll'}}>
                    <Timeline mode="left">
                      {data.logs.map((item, index) => (
                        <Timeline.Item
                          label={makeLocalTime(item?.created_at)}
                          keys={index}
                        >
                          {item?.message}
                        </Timeline.Item>
                      ))}
                    </Timeline>
                  </div>
                ) : (
                  <Empty />
                )}
              </Panel>
            </Collapse>
          </>
          : null}
          <br />
          <br />
        </Wrapper>
    );
  });