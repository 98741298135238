import React from 'react'
//import 'antd/dist/antd.css';
import { Table, Button, Space, Input, Modal, Form } from 'antd';
import Highlighter from 'react-highlight-words';
import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { ticketHelper, claimTicket, updateTicketDept } from "../../actions/support";
import history from '../../history';
import { connect } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import TimeSince from './timeSince';
import { renderSelect } from '../../component/form/form';

const Search = Input.Search;

const TransmissionPending = ({ getTransmissionData, data, ticketHelper, claimTicket, updateTicketDept, getProcessing }) => {
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [access, setAccess] = useState('');
  const [ID, setID] = useState('')
  const [department, setDepartment] = useState(null)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);  
  const [form] = Form.useForm();


  useEffect ( async () => {
    getTransmissionData();
    const helper = await ticketHelper();
    helper?.department.push({'name': 'all'})
    setDepartment(helper?.department);
    setAccess(JSON.parse(localStorage.getItem('access'))?.ticket_management);
  
  }, [])
  
  const handleDept = (val) => {
    setIsEditModalOpen(true);
    setID(val?.id)
    form.setFieldsValue({
      department: val?.type,
    });
  };



  const handleClaim = async (id) => {
    const res =  await claimTicket(id);
    if(res) {
      getTransmissionData();
      getProcessing();
    }
    
  }
  
  const handleView = async (id) => {
    history.push(`/ticket/details?id=${id}`);
  }
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Search
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
      setSearchText('');
  };

  const makeLocalTime = (date) => {
    let mainDate = date.split('T')[0];
    let hours = date.split('T')[1].split(':')[0];
    let minutes = date.split('T')[1].split(':')[1];
    var am = true;
    if (hours > 12) {
        am = false;
        hours -= 12
    } else if (hours == 12) {
        am = false;
    } else if (hours == 0) {
        hours = 12
    }
    return mainDate + " , " + hours+":"+minutes + " " + (am ? "a.m" : "p.m")
  }

  const createOption = (value, label) => {
    return {value, label};
  };

  let deptOption = [];
  deptOption = (
    department ? department.map(item => 
      createOption(item?.name , item?.name ))
  : null)

  const createData = (id, clients, branch, type, problem_type, date, down_time, create_by, action) => {
    return {id, clients, branch, type, problem_type, date, down_time, create_by, action};
  };
  const columns = [
    {
      title: 'Ticket no',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
      ...getColumnSearchProps('id'),
    },
    {
      title: 'Clients/POP',
      dataIndex: 'clients',
      key: 'clients',
      width: '20%',
    },
    {
      title: 'Branch',
      dataIndex: 'branch',
      key: 'branch',
      width: '20%',
    },
    {
      title: 'Department',
      dataIndex: 'type',
      key: 'type',
      width: '20%',
    },
    {
      title: 'Problem type',
      dataIndex: 'problem_type',
      key: 'problem_type',
      width: '20%',
      ...getColumnSearchProps('problem_type'),
    },
    {
      title: 'Date Time',
      dataIndex: 'date',
      key: 'date',
      width: '20%',
    },
    {
      title: 'Down Since	',
      dataIndex: 'down_time',
      key: 'down_time',
      width: '20%',
    },
    {
      title: 'Create By',
      dataIndex: 'create_by',
      key: 'create_by',
      width: '20%',
      ...getColumnSearchProps('create_by'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '10%',
    },
  ];
  const rows = [
    data
      ? data?.list?.slice(0).reverse()?.map((item, key) => createData(
        item?.id,
        <>
        <p style={{ margin: '0px' }}>{item?.client?.name ? item?.client?.name : 'POP: ' + item?.pop?.name }</p>
        </>
        ,
        item?.sub_client?.name ? item?.sub_client?.name : 
        <>
          <p style={{ margin: '0px' }}>{item?.router?.name ? 'Router: ' + item?.router?.name : null}</p>
          <p style={{ margin: '0px' }}>{item?.switch?.name ? 'Switch: ' +item?.switch?.name : null}</p>
          <p style={{ margin: '0px' }}>{(item?.switch_port_no || item?.switch_port_no === 0) ? 'Switch Port: ' + item?.switch_port_no : null}</p>
        </>,
        item?.type,
        item?.problem_type,
        item?.created_at ? makeLocalTime(item?.created_at) : null,
        // item?.created_at ? <ReactTimeAgo date={item?.created_at} locale="en-US"/> : null,
        item?.created_at ? <TimeSince date={(item?.created_at).split('.')[0]} /> : null,
        item?.creator?.name,
        <>
          {access === 'a' ? 
            <>
              <Button size="small" type="primary" onClick={() => handleClaim(item?.id)}>Claim</Button> &nbsp;
        
              <Button size="small" type="primary" title="View" icon={<EyeOutlined  />} onClick={() => handleView(item?.id)}></Button> &nbsp;
            </>
          : null}
          {access === 'w' ? 
            <>
              <Button size="small" type="primary" onClick={() => handleClaim(item?.id)}>Claim</Button> &nbsp;
        
              <Button size="small" type="primary" title="View" icon={<EyeOutlined  />} onClick={() => handleView(item?.id)}></Button> &nbsp;
            </>
          : null}
          {access === 'r' ? 
            <>
              <Button size="small" type="primary" icon={<EyeOutlined  />} onClick={() => handleView(item?.id)}>View</Button> &nbsp;
            </>
          : null}
          {/* <Button size="small" type="danger" icon={<DeleteOutlined  />} onClick={() => handleDelete(item)}>Delete</Button> &nbsp; */}
        </>
        ))
      : "",
  ];

  const onFinish = async (values) => {
    const res =  await updateTicketDept(ID, values)
    if(res ===  201){
      getTransmissionData();
      setID('');
      setIsEditModalOpen(false);
      form.resetFields();
    }
  };

  const onFinishEdit = async (values) => {
    // const res = await updateRouter(values);
    // if(res ===  201){
    //   getData();
    //   setIsEditModalOpen(false);
    // }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };
  
  // const handlePagination = async (e) => {
  //   setPage(e);
  //   const res = await getRouters(e, rId, name, use, popId)
  //   setData(res);
  // }
  
  const status = [
    {value:'used', label: 'In use'},
    {value: 'unused', label: 'Unused'},
  ]


  return ( 
    <Content style={{ margin: '0 16px' }}>

      <Table size='small' columns={columns} pagination={false} dataSource={rows[0]} scroll={{ x: 1000, y: 450 }} sticky/>
      <br />

      {/* <Pagination defaultCurrent={1} pageSize="20" total={data?.count} onChange={(e) => { handlePagination(e); }} /> */}
      
      <Modal
        title="Change Department" 
        open={isEditModalOpen} 
        onOk={() => setIsEditModalOpen(false)} 
        onCancel={() => setIsEditModalOpen(false)} 
        width={800}
        footer={null}
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          {deptOption ?
           renderSelect('department', 'Department', [
              { required: true},
            ],
            'Select a department', deptOption, 'none')
          : null}
          <Form.Item {...tailLayout}> 
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
                
        </Form>
      </Modal>
    </Content>
   );
}
 
export default connect(null, { ticketHelper, claimTicket, updateTicketDept })(TransmissionPending);