import React, { useState } from 'react';
import { Drawer, Form, Input, Button, Space, Select, Upload} from 'antd';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Option } from 'antd/lib/mentions';
import { connect } from 'react-redux';
import {fileUploader,fileDelete} from '../../actions/orders'
const UploadDocs = ({onClose,visible,fileUploader,fileDelete, order}) => {
    const [hasData, setHasData] = useState(false)
    const onFinish = async (values) => {
        const res = await fileUploader(values.doc,order)
        if(res === 201){
            onClose()
        }
        // console.log('Received values of form:', values);
      };
      const props = {
        name: 'file',
        action: 'https://api.getcocrm.hidayahsmart.solutions/getco/sailor/v1/order-management/file/upload',
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        
        onRemove: (file) => {
            if(file?.response?.success === true) fileDelete(file?.response?.name)
        //   alert('oo')
        },
      };
    return ( 
        <>
      <Drawer
        title="Upload Document"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        width="600"
      >
        <Form name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off">
            <Form.List name="doc">
                {(fields, { add, remove }) => (
                <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                        <Form.Item
                        {...restField}
                        name={[name, 'title']}
                        fieldKey={[fieldKey, 'title']}
                        rules={[{ required: true, message: 'Title is required' }]}
                        >
                        <Input placeholder="Title of document" onChange={setHasData(true)} />
                        </Form.Item>

                        <Form.Item
                        {...restField}
                        name={[name, 'file']}
                        fieldKey={[fieldKey, 'file']}
                        rules={[{ required: true, message: 'File is required' }]}
                        >
                            <Upload {...props} accept="png">
                                <Button icon={<UploadOutlined />} style={{width: '100%', display: 'flex', justifyContent: 'center'}}>Upload</Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item
                        {...restField}
                        name={[name, 'download_able']}
                        fieldKey={[fieldKey, 'download_able']}
                        rules={[{ required: true, message: 'This field is required' }]}
                        >
                            <Select
                                placeholder="Is this file downloadable?"
                                allowClear
                            >
                                <Option value={true}>Yes</Option>
                                <Option value={false}>No</Option>
                            </Select>
                        </Form.Item>

                        <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                    ))}
                    <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add field
                    </Button>
                    </Form.Item>
                </>
                )}
            </Form.List>
            <Form.Item>
                {hasData ?
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                :
                <Button type="primary" htmlType="submit" disabled>
                    Submit
                </Button>
                }
            </Form.Item>
        </Form>
      </Drawer>
        </>
     );
}
 
export default connect(null,{fileUploader,fileDelete})(UploadDocs);