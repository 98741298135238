import React, { Component } from 'react'
//import 'antd/dist/antd.css';
import { Table, Input, Button, Space, PageHeader, Popconfirm, Breadcrumb,Tag, Drawer, Modal, Form, Pagination    } from 'antd';
import Highlighter from 'react-highlight-words';
import { CheckCircleOutlined, DeleteOutlined, EditOutlined, EyeOutlined, PlusCircleFilled, SearchOutlined,SettingOutlined,StopOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { addEmployee, UpdateEmployee, deleteApi, getOptions, getEmployee, helper } from "../../actions/setting";
import { Link } from 'react-router-dom';
import history from '../../history';
import { connect } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { renderDate, renderInput, renderSelect } from '../../component/form/form';
import dayjs from 'dayjs';
import moment from 'moment';
// import { renderTextField } from '../../../component/form/form';
// import Remarks from '../../../component/remark';
// import ModalForm from './modalForm';
// import EditModalForm from './editModalForm';
const Search = Input.Search;
const Select = Input.Search;
const showTotal = (total) => `Total ${total} items`;

const Employee = ({ getEmployee, addEmployee, UpdateEmployee, deleteApi, getOptions, helper }) => {
  const [ID, setID] = useState('')
  const [page, setPage] = useState(1)
  const [allOptions, setAllOptions] = useState(null)
  const [data, setData] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [departmentOption, setDepartmentOption] = useState([]);
  const [designationOption, setDesignationOption] = useState([]);
  const [form] = Form.useForm();
  const [cform] = Form.useForm();

  const createOption = (value, label) => {
    return {value, label};
  };

  const getData = async () => {
    const res = await getEmployee(page)
    setData(res);
    const options = await helper();
    const dOption = [...
      options
        ? options?.department?.map((item) => createOption(
          item, 
          item,
          ))
        : "",
    ];
    const deOption = [...
      options
        ? options?.designation?.map((item) => createOption(
          item, 
          item,
          ))
        : "",
    ];
    const res1 = await getOptions(1)
    setAllOptions(res1);
    setDepartmentOption(dOption);
    setDesignationOption(deOption)
  }
  useEffect(() => {
    //window.scrollTo(0, 0);
    getData()
  }, [])

  const showModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleOk = () => {
    setIsCreateModalOpen(false);
  };

  const handleCancel = () => {
    setIsCreateModalOpen(false);
  };

  const handleEdit = async (item) => {
    setID(item?.id)
    setIsEditModalOpen(true);
    form.setFieldsValue({
      name: item?.name,
      employee_id: item?.employee_id,
      mobile_number: item?.mobile_number,
      email: item?.email,
      designation: item?.designation,
      department: item?.department,
      address: item?.address,
    });
    if(item?.dob ){
      form.setFieldsValue({
        dob: moment(item?.dob),
      })
    }
    if(item?.join_date ){
      form.setFieldsValue({
        join_date:moment(item?.join_date),
      })
    }
  }
  
  const handleDelete = async (item) => {
    const res =  await deleteApi(item?.id, 'employee');
    if(res === 201){
      getData();
    };
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Search
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
      setSearchText('');
  };

  const createData = (id, name, employee_id, dob, join_date, mobile_number, email, designation, department, address, action) => {
    return {id, name, employee_id, dob, join_date, mobile_number, email, designation, department, address, action};
  };


  const columns = [
    {
      title: 'SL',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
      ...getColumnSearchProps('id'),
    },
    {
      title: 'Employee ID',
      dataIndex: 'employee_id',
      key: 'employee_id',
      width: '60%',
      ...getColumnSearchProps('employee_id'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '60%',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Date of Birth',
      dataIndex: 'dob',
      key: 'dob',
      width: '60%',
      ...getColumnSearchProps('dob'),
    },
    {
      title: 'Joining Date',
      dataIndex: 'join_date',
      key: 'join_date',
      width: '60%',
      ...getColumnSearchProps('join_date'),
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobile_number',
      key: 'mobile_number',
      width: '60%',
      ...getColumnSearchProps('mobile_number'),
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      key: 'designation',
      width: '60%',
      ...getColumnSearchProps('designation'),
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      width: '60%',
      ...getColumnSearchProps('department'),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width: '60%',
      ...getColumnSearchProps('address'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '20%',
    },
  ];

  const rows = [
    data
      ? data?.list?.map((item, key) => createData(
        key + 1, 
        item.employee_id,
        item.name,
        item.dob,
        item.join_date,
        item.mobile_number,
        item.email,
        item.designation,
        item.department,
        item.address,
        <>
          <Button size="small" type="primary" icon={<EditOutlined  />} onClick={() => handleEdit(item)}>Edit</Button> &nbsp;
          <Button size="small" type="danger" icon={<DeleteOutlined  />} onClick={() => handleDelete(item)}>Delete</Button> &nbsp;
        </>
        ))
      : "",
  ];


  const onFinish = async (values) => {
    const res = await addEmployee(values, ID);
    if(res ===  201){
      getData();
      setIsCreateModalOpen(false);
      cform.resetFields();
    }
  };

  const onFinishEdit = async (values) => {
    const res = await UpdateEmployee(values, ID);
    if(res ===  201){
      getData();
      setIsEditModalOpen(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const handlePagination = async (e) => {
    setPage(e);
    const res = await getEmployee(e)
    setData(res);
  }
  return ( 
    <Content style={{ margin: '0 16px' }}>

      <Breadcrumb style={{padding:"10px 0px"}}>
        <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Employee</Breadcrumb.Item>
      </Breadcrumb>

      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Employee"
        subTitle="Setting Management"
        extra={[
          <Button key="1"  type="primary" icon={<PlusCircleFilled />} onClick = { showModal}>Create New
          </Button>,
        ]}
      >
      </PageHeader>
      <Table size='small' columns={columns} pagination={false} dataSource={rows[0]} scroll={{ x: 1000 ,y: 400 }} sticky/>
      <br />
      <Pagination size='small' showTotal={showTotal} pageSizeOptions={[20]} current={page} pageSize="20" total={data?.count} onChange={(e) => { handlePagination(e); }} />
      <Modal 
        title="Add Employee" 
        open={isCreateModalOpen} 
        onOk={handleOk} 
        onCancel={handleCancel} 
        width={800}
        footer={null}
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={cform}
        >
              
          {renderInput('employee_id', 'Employee Id', [
              { required: true, message: 'This field is required!' },
            ])
          }
          {renderInput('name', 'Employee Name', [
              { required: true, message: 'This field is required!' },
            ])
          }
          {renderDate('dob', 'Date of birth', [
              { required: false, message: 'This field is required!' },
            ])
          }
          {renderDate('join_date', 'Joining Date', [
              { required: false, message: 'This field is required!' },
            ])
          }
          {renderInput('mobile_number', 'Mobile Number', [
              { required: true, message: 'This field is required!' },
            ])
          }
          {renderInput('email', 'Email', [
              { required: false, message: 'This field is required!' },
            ])
          }
          {renderSelect('department', 'Department', [
              { required: true, message: 'This field is required!' },
            ],' ', departmentOption)
          }
          {renderSelect('designation', 'Designation', [
              { required: true, message: 'This field is required!' },
            ],' ', designationOption)
          }
          {renderInput('address', 'Address', [
              { required: false, message: 'This field is required!' },
            ])
          }
          <Form.Item {...tailLayout}> 
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
                
        </Form>
      </Modal>
      
      <Modal 
        title="Edit Employee" 
        open={isEditModalOpen} 
        onOk={() => setIsEditModalOpen(false)} 
        onCancel={() => setIsEditModalOpen(false)} 
        width={800}
        footer={null}
      >
        <Form
          {...layout}
          name="Edit "
          initialValues={{ remember: true }}
          onFinish={onFinishEdit}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          
          {renderInput('employee_id', 'Employee Id', [
              { required: true, message: 'This field is required!' },
            ])
          }
          {renderInput('name', 'Employee Name', [
              { required: true, message: 'This field is required!' },
            ])
          }
          {renderDate('dob', 'Date of birth', [
              { required: false, message: 'This field is required!' },
            ])
          }
          {renderDate('join_date', 'Joining Date', [
              { required: false, message: 'This field is required!' },
            ])
          }
          {renderInput('mobile_number', 'Mobile Number', [
              { required: true, message: 'This field is required!' },
            ])
          }
          {renderInput('email', 'Email', [
              { required: false, message: 'This field is required!' },
            ])
          }
          {renderSelect('department', 'Department', [
              { required: true, message: 'This field is required!' },
            ],' ', departmentOption)
          }
          {renderSelect('designation', 'Designation', [
              { required: true, message: 'This field is required!' },
            ],' ', designationOption)
          }
          {renderInput('address', 'Address', [
              { required: false, message: 'This field is required!' },
            ])
          }

          {/* {renderInput('designation', 'Designation', [
              { required: true, message: 'This field is required!' },
            ])
          }
          {renderInput('department', 'Department', [
              { required: true, message: 'This field is required!' },
            ])
          } */}
          <Form.Item {...tailLayout}> 
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
                
        </Form>
      </Modal>
      
    </Content>
   );
}
 
export default connect(null, {getEmployee, addEmployee, UpdateEmployee, deleteApi, getOptions, helper})(Employee);