import React, { Component } from 'react'
//import 'antd/dist/antd.css';
import { Table, Input, Button, Space, PageHeader, Breadcrumb,Tag, Form, Tabs, Row, Col, Tooltip, Select, DatePicker    } from 'antd';
import Highlighter from 'react-highlight-words';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { bandwidthReportDownloader } from "../../actions/subClients";
import { helper } from "../../actions/clients";

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import moment from 'moment';
import NewConnection from './newConnection';
import ChangedConnection from './changedConnection';
const { RangePicker } = DatePicker;
const Search = Input.Search;
const { Option } = Select;
const showTotal = (total) => `Total ${total} items`;

const BandwidthReport = ({ 
  helper, bandwidthReportDownloader,
 }) => {
  const [allclients, setAllClients] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [CID, setCID] = useState(null)
 
  const [newData, setNewData] = useState([])
  const [changedData, setChangedData] = useState([])
  const [data, setData] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const createOption = (value, label) => {
    return {value, label};
  };

  useEffect( async () => {
    const option =  await helper();
    
    const cli = [... 
      option?.client_name?.map((item, key) => (
        item?.status === 'active' ?
          createOption(
            item?.name + ',' +  item?.id,
            item?.name,
          )
        :
        []
      ))
    ];
    
    setAllClients(cli);
  }, []);

  const handleApiSearch = async () => {
    if(startDate && endDate) {
      const res = await bandwidthReportDownloader(startDate, endDate, CID, false)
      setNewData(res?.new_connection);
      setChangedData(res?.bandwidth_change);
    }
    else {
      alert('Please select date')
    }
  };

  const handleBandwidthHownload = async () => {
    
    if(startDate && endDate) {
      const res = await bandwidthReportDownloader(startDate, endDate, CID, true);
    }
    else {
      alert('Please select date')
    }
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Search
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
      setSearchText('');
  };

  const makeLocalTime = (date) => {
    let hours = date.split("T")[1].split(":")[0];
    let minutes = date.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours == 12) {
      am = false;
    } else if (hours == 0) {
      hours = 12;
    }
    return (
      date.split("T")[0] +
      "," +
      hours +
      ":" +
      minutes +
      " " +
      (am ? "A.M" : "P.M")
    );
  };

  const capitalizer = s => 
    s.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')
  
  const createData = (id, client_id, name, area, address, pop, connection_type, district, status, inst_date, bill_start_date, end_date, action) => {
    return {id, client_id, name, area, address, pop, connection_type, district, status, inst_date, bill_start_date, end_date, action};
  };

  const columns = [
    // {
    //   title: 'SL',
    //   dataIndex: 'sl',
    //   key: 'sl',
    //   width: '10%',
    // },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '20%',
      ...getColumnSearchProps('id'),
    },
    {
      title: 'Client',
      dataIndex: 'client_id',
      key: 'client_id',
      width: '20%',
      ...getColumnSearchProps('client_id'),
    },
    {
      title: 'Branch Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Area',
      dataIndex: 'area',
      key: 'area',
      width: '20%',
      ...getColumnSearchProps('area'),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width: '20%',
      ...getColumnSearchProps('address'),
    },
    {
      title: 'Pop',
      dataIndex: 'pop',
      key: 'pop',
      width: '20%',
      ...getColumnSearchProps('pop'),
    },
    {
      title: 'Connection Type',
      dataIndex: 'connection_type',
      key: 'connection_type',
      width: '20%',
      ...getColumnSearchProps('connection_type'),
    },
    {
      title: 'District',
      dataIndex: 'district',
      key: 'district',
      width: '20%',
      ...getColumnSearchProps('district'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
    },
    {
      title: 'Inst. Date',
      dataIndex: 'inst_date',
      key: 'inst_date',
      width: '20%',
    },
    {
      title: 'Bill Start Date',
      dataIndex: 'bill_start_date',
      key: 'bill_start_date',
      width: '20%',
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
      width: '20%',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '20%',
    },
  ];

  const rows = [
    data
      ? data?.list?.map((item, key) => createData(
        // key + 1, 
        item?.id, 
        item?.client?.name + '(' + item?.client?.id + ')',
        item?.name,  
        item?.area, 
        item?.address, 
        item?.pop?.name + '(' + item?.pop?.id + ')',
        item?.connection_type, 
        item?.district,  
        
        item?.status == 'active' ? 
          (
            <Tag color="#6fcf20">Active</Tag>
          ) 
          : 
          item?.status == 'inactive' ? 
          (
            <Tag color="#e96710">Inactive</Tag>
          ) 
          : 
          item?.status == 'blocked' ? 
          (
            <Tag color="#e74c3c">Blocked</Tag>
          ) 
          : 
          (
            <Tag color="#f1c40f">Temporary close</Tag>
          ), 
        item?.inst_date ? makeLocalTime(item?.inst_date) : '', 
        item?.bill_start_date ? makeLocalTime(item?.bill_start_date) : '', 
        item?.end_date ? makeLocalTime(item?.end_date) : '', 
        ))
      : "",
  ];

  const handleDate = (date) => {
    if (date) {
      const ds = new Date(date[0]._d);
      const de = new Date(date[1]._d);
      setStartDate(ds.toISOString());
      setEndDate(de.toISOString());
    } else {
      setStartDate(null);
      setEndDate(null);
      return;
    }
  };
  const items = [
    {
      key: '1',
      label: `New Connection`,
      children: <NewConnection data={newData} />,
    },
    {
      key: '2',
      label: `Bandwidth Change`,
      children: <ChangedConnection data={changedData} />,
    },
  ];
  return ( 
    <Content style={{ margin: '0 16px' }}>

      <Breadcrumb style={{padding:"10px 0px"}}>
        <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Bandwidth Report</Breadcrumb.Item>
      </Breadcrumb>

      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Bandwidth Report"
        subTitle="Client Management"
      >
      </PageHeader>

      <Row>
       
        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
          <RangePicker
            style={{width:'99%'}}
            name="date"
            onChange={handleDate}
            format="YYYY/MM/DD"
            value={startDate ? [moment(startDate), moment(endDate)] : ''}
          />
          
        </Col>
        <Col xs={10} sm={10} md={6} lg={6} xl={6}>
          <Select
            showSearch
            name="client"
            placeholder="Select Client"
            size="middle"
            allowClear
            style={{width:'99%'}} 
            onChange={(e)=> {setCID(e)}}
            onClear={(e)=> {setCID('')}}
            value={CID}
            
          >
              <Option value=''>None</Option>
              {allclients.map(item => (
                item.value ? <Option value={item.value}>{item.label}</Option> : null
              ))}
          </Select>
        </Col>
        
        <Col xs={2} sm={2} md={3} lg={3} xl={3}>
            <Tooltip title="search" onClick={handleApiSearch}>
            <Button style={{width:'99%'}} type="primary" icon={<SearchOutlined />} >Search</Button>
            </Tooltip>
        </Col>
        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
            <Tooltip title="search" onClick={handleBandwidthHownload}>
            <Button style={{width:'99%'}} type="primary" icon={<DownloadOutlined />} >Download</Button>
            </Tooltip>
        </Col>
      </Row>
      <br />
      <Tabs defaultActiveKey="1" items={items} />
      {/* <Table size='small' columns={columns} pagination={false} dataSource={rows[0]} scroll={{ x: 1000, y:450 }} sticky/> */}
      <br />

    </Content>
   );
}
 
export default connect(null, {helper, bandwidthReportDownloader})(BandwidthReport);