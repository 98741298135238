import React, { Component } from "react";
//import 'antd/dist/antd.css';
import {
  Table,
  Collapse,
  Input,
  Button,
  Space,
  PageHeader,
  Popconfirm,
  Breadcrumb,
  Tag,
  Drawer,
  Modal,
  Form,
  Pagination,
  Row,
  Col,
  Tooltip,
} from "antd";
import Highlighter from "react-highlight-words";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusCircleFilled,
  SearchOutlined,
  SettingOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import history from "../../history";
import { connect } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import {
  renderInput,
  renderSelect,
  renderTextField,
} from "../../component/form/form";
import SupportPending from "./supportPending";
import TransmissionPending from "./transmissionPending";
import ProcessingTicket from "./processingTicket";
import TodayTicket from "./todayTicket";
import {
  processingList,
  supportPendingTicket,
  transmissionPendingTicket,
  todayTicket,
} from "../../actions/support";

const Search = Input.Search;
const { Panel } = Collapse;
const TicketList = ({
  processingList,
  supportPendingTicket,
  transmissionPendingTicket,
  todayTicket,
}) => {
  const [supportData, setSupportData] = useState(null);
  const [transmissionData, setTransmissionData] = useState(null);
  const [data, setData] = useState(null);
  const [today, setToday] = useState(null);
  const [supCount, setSupCount] = useState(null);
  const [transCount, setTransCount] = useState(null);
  const [proCount, setProCount] = useState(null);
  const [todayCount, setTodayCount] = useState(null);

  // useEffect( async () => {
  //   const res1 = await supportPendingTicket();
  //   setSupportData(res1);
  //   setSupCount(res1?.list?.length)
  //   const res2 = await transmissionPendingTicket();
  //   setTransmissionData(res2);
  //   setTransCount(res2?.list?.length)
  // }, [])

  const getSupportDatas = async () => {
    const res = await supportPendingTicket();
    setSupportData(res);
    setSupCount(res?.list?.length);
  };

  const getTransmissionData = async () => {
    const res = await transmissionPendingTicket();
    setTransmissionData(res);
    setTransCount(res?.list?.length);
  };

  const getProcessing = async () => {
    const res = await processingList();
    setData(res);
    setProCount(res?.list?.length);
  };

  const todayData = async () => {
    const res = await todayTicket();
    setToday(res);
    setTodayCount(res?.list?.length);
  };

  const onChange = (key) => {
    // console.log(key);
  };

  return (
    <Content style={{ margin: "0 16px" }}>
      <Breadcrumb style={{ padding: "10px 0px" }}>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Ticket Board</Breadcrumb.Item>
      </Breadcrumb>

      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Ticket Board"
        subTitle="Ticket Management"
      ></PageHeader>
      {/* 
      <Collapse defaultActiveKey={['1']} onChange={onChange}>
        <Panel header={"Department Queue " + '(' + supCount + ')'} key="1">
          <SupportPending 
            count = {setSupCount}
            getProcessing={getProcessing} 
            getSupportDatas={getSupportDatas} 
            data = {supportData}  
          />
        </Panel>
        <Panel header={"Other Queue " + '(' + transCount + ')'} key="2">
          <TransmissionPending 
            getProcessing={getProcessing} 
            getTransmissionData={getTransmissionData}
            data = { transmissionData }
          />
        </Panel>
        <Panel header={"Now Serving " + '(' + supCount + ')'} key="3">
          <ProcessingTicket 
            getProcessing={getProcessing} data={data} setData = {setData}
            todayData={todayData} set={setToday}
            getSupportDatas={getSupportDatas}
            getTransmissionData={getTransmissionData}
          />
        </Panel>
        <Panel header={"Today Solved " + '(' + today + ')'} key="4">
          <TodayTicket todayData={todayData} data={today} />
        </Panel>
      </Collapse> */}

      <Collapse defaultActiveKey={["1"]} onChange={onChange}>
        <Panel header={"Department Queue " + "(" + supCount + ")"} key="1">
          <SupportPending
            count={setSupCount}
            getProcessing={getProcessing}
            getSupportDatas={getSupportDatas}
            data={supportData}
          />
        </Panel>
      </Collapse>

      <Collapse defaultActiveKey={["1"]} onChange={onChange}>
        <Panel header={"Other Queue " + "(" + transCount + ")"} key="1">
          <TransmissionPending
            count={setTransCount}
            getProcessing={getProcessing}
            getTransmissionData={getTransmissionData}
            data={transmissionData}
          />
        </Panel>
      </Collapse>

      <Collapse defaultActiveKey={["1"]} onChange={onChange}>
        <Panel header={"Now Serving " + "(" + proCount + ")"} key="1">
          <ProcessingTicket
            count={setProCount}
            getProcessing={getProcessing}
            data={data}
            setData={setData}
            todayData={todayData}
            set={setToday}
            getSupportDatas={getSupportDatas}
            getTransmissionData={getTransmissionData}
          />
        </Panel>
      </Collapse>

      <Collapse defaultActiveKey={["1"]} onChange={onChange}>
        <Panel header={"Today Solved " + "(" + todayCount + ")"} key="1">
          <TodayTicket
            count={setTodayCount}
            todayData={todayData}
            data={today}
          />
        </Panel>
      </Collapse>
    </Content>
  );
};

export default connect(null, {
  processingList,
  supportPendingTicket,
  transmissionPendingTicket,
  todayTicket,
})(TicketList);
