import React, { Component } from 'react'
//import 'antd/dist/antd.css';
import { Table, Input, Button, Space, PageHeader,Select, Popconfirm, Breadcrumb,Tag, Drawer, Modal, Form, Pagination, Row, Col, Tooltip, Descriptions    } from 'antd';
import Highlighter from 'react-highlight-words';
import { CheckCircleOutlined, DeleteOutlined, EditOutlined, EyeOutlined, PlusCircleFilled, SearchOutlined,SettingOutlined,StopOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { createSupportTicket, supportHelper, supportHelperById, ticketHelper, } from "../../actions/support";
import { Link } from 'react-router-dom';
import history from '../../history';
import { connect } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { renderInput, renderSelect, renderTextField } from '../../component/form/form';
import { getSubClientDetails } from '../../actions/subClients';
import ClientsServiceDetails from '../clients/clientsServiceDetails';
// import { renderTextField } from '../../../component/form/form';
// import Remarks from '../../../component/remark';
// import ModalForm from './modalForm';
// import EditModalForm from './editModalForm';
const Search = Input.Search;
const { Option } = Select;

const CreateSupport = ({ createSupportTicket, supportHelper, supportHelperById, ticketHelper, getSubClientDetails}) => {
  const [allService, setAllService] = useState(null);
  const [clients, setClients] = useState([]);
  const [problems, setProblems] = useState([]);
  const [descriptions, setDescriptions] = useState(null);
  const [department, setDepartment] = useState(null)
  const [branchId, setBranchId] = useState(null)
  const [branchDetails, setBranchDetails] = useState(null)
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false)
  const [statusVal, setStatusVal] = useState(null)
  const [form] = Form.useForm();
  const [cform] = Form.useForm();
  
  const createOption = (value, label) => {
    return {value, label};
  };

  useEffect( async () => {
    const res = await supportHelper();
    if(res){
      const coption = [... 
        res?.client_list?.map((item, key) => (
          item?.status === 'active' ?
            createOption(
            item.name + ',-,' + item.id, 
            item.name,
          )
          :
          []
          )
        )
      ]
      const poption = [... 
        res?.problemList?.map((item, key) => createOption(
        item.name, 
        item.name,
        ))
      ]
  
      setClients(coption);
      setProblems(poption);

    }
    
    const helper = await ticketHelper();
    helper?.department.push({'name': 'all'})
    setDepartment(helper?.department);
  }, [])
  

  const status = [
    {value: 'active', label: 'Active'},
    {value: 'half done', label: 'Half Done'},
    {value: 'completed', label: 'Completed'},
  ]

  const priority = [
    {value: 'normal', label: 'Normal'},
    {value: 'medium', label: 'Medium'},
    {value: 'high', label: 'High'},
  ]

  let deptOption = [];
  deptOption = (
    department ? department.map(item => 
      createOption(item?.name , item?.name ))
  : null)

  const onFinish = async (values) => {
    const res = await createSupportTicket(values);
    if(res ===  201){
      cform.resetFields();
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };
  

  const handleServiceSelect = async (e) => {
    setBranchId(null);
    if(e){
      cform.setFieldsValue({
        sub_client: '',
      });
      const res =  await supportHelperById(e.split(',-,')[1]);
      if(res) {
        
      setDescriptions(res?.sun_client_list)
      const services = [... 
        res?.sun_client_list?.map((item, key) => 
        item?.status === 'active' ?
        createOption(
          item?.name + ',-,' + item?.id, 
        item?.name+ ' -- ' +item?.id,
        ): [])
      ]
      setAllService(services)
      }
      }
  };

  const handleService = async (e) => {
    setBranchId(e.split(',-,')[1]);
    descriptions?.map(item => {
      if(item?.id === e.split(',-,')[1]){
        let a = "";
        if(item?.pop?.id && item?.router?.id && item?.switch?.id) {
          a = (
            'Microtik port: ' + item?.microtik_port + ' | ' + 'Pop: ' + item?.pop?.id + ' (' + item?.pop?.name + ') ' +
            ' | ' + 'Router: ' + item?.router?.id + ' (' + item?.router?.name+ ') ' +
            ' | ' + 'Switch: ' + item?.switch?.id + ' (' + item?.switch?.name+ ') ' + ' | Switch port: ' + item?.switch_port_no 
            );
        }
        else if(item?.pop?.id && item?.router?.id && !item?.switch?.id) {
          a = (
            'Microtik port: ' + item?.microtik_port + ' | ' + 'Pop: ' + item?.pop?.id + ' (' + item?.pop?.name + ') ' +
            ' | ' + 'Router: ' + item?.router?.id + ' (' + item?.router?.name+ ') ' +
            ' | ' + 'Switch: -- '
            );
        }
        else {
          a = (
            'Microtik port: ' + item?.microtik_port + ' | ' + 'Pop: ' + item?.pop?.id + ' (' + item?.pop?.name + ') ' +
            ' | ' + 'Router: -- ' +
            ' | ' + 'Switch: --'
            );
        }
        cform.setFieldsValue({
          description:  a,
          // item?.microtik_port + item?.router ? item?.router?.name : '',
            // item?.microtik_port ? 'microtik_port: ' + item?.microtik_port : null + 
            // item?.pop ? 'Pop: ' + item?.pop?.name : null +
            // item?.router ? 'Router: ' + item?.router?.name : null +
            // item?.router ? 'Switch: ' + item?.router?.switch : null 
            // ,
        });
        // console.log(item)
      }
  })
   
  };
  
  const handleView = async () => {
    const res =  await getSubClientDetails(branchId)
    setBranchDetails(res)
    setIsDetailModalOpen(true)
  }

  return ( 
    <Content style={{ margin: '0 16px' }}>

      <Breadcrumb style={{padding:"10px 0px"}}>
        <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Create Ticket Client</Breadcrumb.Item>
      </Breadcrumb>

      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Create Ticket Client"
        subTitle="Ticket Management"
        // extra={[
        //   <Button key="1"  type="primary" icon={<PlusCircleFilled />} >Create New
        //   </Button>,
        // ]}
      >
      </PageHeader>
      
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={cform}
        >
          <Form.Item
            name="client"
            label="Client"
            rules={[{ required: true, message: 'This field is required!' }]}
          >
            <Select
              showSearch
              placeholder="Select a client"
              size="large"
              allowClear
              onChange={(e) => handleServiceSelect(e)}
            >
                {clients?.map(item => (
                    item.value ? <Option value={item.value}>{item.label}</Option> : null
                ))}
            </Select>
          </Form.Item> 
          

          {allService ? 
          <Form.Item
              name="sub_client"
              label="Branch"
              rules={[{ required: true, message: 'This field is required!' }]}
          >
              <Select
                showSearch
                placeholder="Select a servce"
                size="large"
                allowClear
                onChange={(e) => handleService(e)}
              >
                  {allService?.map(item => (
                      <Option value={item.value}>{item.label}</Option>
                  ))}
              </Select>
            </Form.Item> 
            // renderSelect('sub_client', 'Client service', [
            //     { required: true, message: 'This field is required!' },
            // ],' ', allService)
          :null}
          {branchId ?
          <Row>
            <Col xs={8}></Col>
            <Col xs={16}>
            <Button size='middle' type='primary' onClick={handleView}>View</Button>
            </Col>
            
            <Col xs={24}><br /></Col>
            <Modal 
              title="Client Service Details" 
              open={isDetailModalOpen} 
              onOk={() => setIsDetailModalOpen(false)} 
              onCancel={() => setIsDetailModalOpen(false)} 
              width={1300}
              footer={null}
            >
              <ClientsServiceDetails data={branchDetails} />
            </Modal>
          </Row>
          : null}
          {renderInput('contact_person', 'Contact Person', [
            // { required: true, message: 'This field is required!' },
          ], 'large')
          }

          {renderInput('mobile_number', 'Mobile Number', [
            // { required: true, message: 'This field is required!' },
          ], 'large')
          }

          {deptOption ?
            renderSelect('department', 'Department', [
              { required: true},
            ],
            'Select a department', deptOption, 'none')
          : null}

          {renderSelect('problem_type', 'Problem type', [
              { required: true, message: 'This field is required!' },
            ],' ', problems)
          }
          
          {renderSelect('priority', 'Priority', [
              { required: true, message: 'This field is required!' },
            ],' ', priority)
          }

          {renderInput('subject', 'Subject', [
            { required: true, message: 'This field is required!' },
          ], 'large')
          }
          {/* {renderSelect('client', 'Client', [
              { required: true, message: 'This field is required!' },
            ],' ', clients)
          } */}
          

          {renderTextField('description', 'Description', [
              { required: true, message: 'This field is required!' },
            ], 'small')
          }

          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: false, message: 'This field is required!' }]}
          >
            <Select
              showSearch
              placeholder="Select Status"
              size="large"
              allowClear
              onChange={(e) => setStatusVal(e)}
            >
                {status?.map(item => (
                    <Option value={item.value}>{item.label}</Option>
                ))}
            </Select>
          </Form.Item> 
          
          {renderTextField('remark', 'Solution', [
              { required: statusVal === 'completed' ? true : false, message: 'This field is required!' },
              ], 'small')
          }
          
           
          <Form.Item {...tailLayout}> 
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
                
        </Form>
      
    </Content>
   );
  }
export default connect(null, { createSupportTicket, supportHelper, supportHelperById, ticketHelper, getSubClientDetails, })(CreateSupport);