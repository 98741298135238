import React, { useEffect, useRef } from "react";
import {
  Descriptions,
  Tag,
  Empty,
  Timeline,
  Button,
} from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import { Title, Wrapper } from "./detailStyle";
import { Content } from "antd/lib/layout/layout";
import ReactToPrint from 'react-to-print';
import { ClientToprint } from "./ClientToprint";

const ClientDetails = ({ data  }) => {
  const componentRef = useRef();

  const makeLocalTime = (date) => {
    let hours = date.split("T")[1].split(":")[0];
    let minutes = date.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours == 12) {
      am = false;
    } else if (hours == 0) {
      hours = 12;
    }
    return (
      date.split("T")[0] +
      "," +
      hours +
      ":" +
      minutes +
      " " +
      (am ? "A.M" : "P.M")
    );
  };

  const content = (item) => {
    return <div>
    <p style={{ margin: '2px' }}>Unit Data: {item?.unit_data}</p>
    <p style={{ margin: '2px' }}>Conatct Amount: {item?.contact_amount}</p>
    <p style={{ margin: '2px' }}>Rate Amount: {item?.rate_amount}</p>
    <p style={{ margin: '2px' }}>Vat Include: {item?.vat_include ? 'Vat included' : 'Not Included'}</p>
    <p style={{ margin: '2px' }}>Vat Amount: {item?.vat_amount}</p>
    <p style={{ margin: '2px' }}>Vat Rate: {item?.vat_rate}</p>
    <p style={{ margin: '2px' }}>Internate: {item?.internet}</p>
    <p style={{ margin: '2px' }}>Youtube: {item?.youtube}</p>
    <p style={{ margin: '2px' }}>Other: {item?.other}</p>
    {/* <p style={{ margin: '2px' }}>Notes: {item?.other_band_name}</p> */}
  </div>
  }

  const capitalizer = s => 
    s.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')
  
    return (
    <Content style={{ margin: "0 16px", }}>
      <div style={{}}>
        <ReactToPrint
          trigger={() => <Button type="primary">Print this out!</Button>}
          content={() => componentRef.current}
        />
      </div>
        <ClientToprint data={data} ref={componentRef} />
      {/* <Wrapper>
        <h2>
          Name : {data?.name}
        </h2>
        {
          data?.status == 'active' ? 
          (
            <Tag color="#6fcf20">Active</Tag>
          ) 
          : 
          data?.status == 'inactive' ? 
          (
            <Tag color="#e96710">Inactive</Tag>
          ) 
          : 
          data?.status == 'blocked' ? 
          (
            <Tag color="#e74c3c">Blocked</Tag>
          ) 
          : 
          (
            <Tag color="#f1c40f">Hold</Tag>
          )
        }

        <Tag color="#cd2df5">
          {data?.created_at ? makeLocalTime(data?.created_at) : null}
        </Tag>
        <br />
        <br />
        {data ? (
          <>
            <Title>General Information</Title>
            <Descriptions>
              <Descriptions.Item label="ID">
                <strong>{data?.id}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="CLIENT NAME">
                <strong>{data?.name}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="EMAIL">
                <strong>{data?.email}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="ADDRESS">
                <strong>{data?.address}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="DISTRICT">
                <strong>{data?.district}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="AREA">
                <strong>{data?.area}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="BUSINESS TYPE">
                <strong>{data?.business_type}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="WEB ADDRESS">
                <strong>{data?.web_address}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="BULK EMAIL">
                <strong>{data?.bulk_email}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="DESCRIPTION">
                <strong>{data?.description}</strong>
              </Descriptions.Item>
            </Descriptions>

          
            <Title>Bill Information</Title>
            <Descriptions>
              <Descriptions.Item label="BILLING TYPE">
                <strong>{capitalizer(data?.billing_type)}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="COLLECTED BY">
                <strong>{data?.bill_collection_by}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="SOLD BY">
                <strong>{data?.sale_by}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="DISCOUNT BY">
                <strong>{data?.discount}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="ADDITIONAL AMOUNT">
                <strong>{data?.addition_amount}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="INVOICE TYPE">
                <strong>{data?.invoice_type}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="VAT STATUS">
                <strong>{data?.vat_status == 'yes' ? 'Yes' : 'No'}</strong>
              </Descriptions.Item>
            </Descriptions>
            
            <Title>Contact Information</Title>
            {data?.contact_person?.map((item, key) => (
              <>
              <h3>Contact Person {key + 1}</h3>
              <hr />
              <Descriptions>
              <Descriptions.Item label="NAME">
                <strong>{item?.name}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="EMAIL">
                <strong>{item?.email}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="PHONE">
                <strong>{item?.number}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="DESCRIPTION">
                <strong>{item?.description}</strong>
              </Descriptions.Item>
              </Descriptions>
              </>
            ))}
            
            <br />
            {data?.logs ? (
              <Timeline mode="left">
                {data.logs.map((item, index) => (
                  <Timeline.Item
                    label={makeLocalTime(item?.created_at)}
                    keys={index}
                  >
                    {item?.message}
                  </Timeline.Item>
                ))}
              </Timeline>
            ) : (
              <Empty />
            )}
          </>
        ) : null}
        <br />
        <br />
      </Wrapper> */}
      {/* <Remarks
        title=" "
        btn="Submit"
        placement="bottom"
        visible={remark}
        fnc={() => setRemark(false)}
        id={[data?.email, auth]}
        delFnc={updateClientAuth}
        refresh={handleRefresh}
      /> */}
    </Content>
  );
};

export default connect(null, { })(
  ClientDetails
);
