import api from "./apis";
import {
  ERROR_MESSAGE,
  LOADED,
  LOADEDING,
  SUCCESS_MESSAGE,
  VALIDATION_ERROR,
} from "./types";
import history from "../history";

export const getSubClients =
  (p, id, n, s, c, pop, router, switche, ds, de) => async (dispatch) => {
    dispatch({ type: LOADEDING });

    console.log(n);
    try {
      let response = null;
      if (ds !== null && de !== null) {
        response = await api(localStorage.getItem("token")).get(
          `/client-management/sub-client?p=${p}&id=${id ? id : ""}&n=${
            n ? encodeURIComponent(n) : ""
          }&s=${s ? s : ""}&c=${c ? c?.split(",")[1] : ""}&pop=${
            pop?.split(",")[1] ? pop?.split(",")[1] : ""
          }&router=${router ? router : ""}&c_switch=${
            switche ? switche : ""
          }&ds=${ds ? ds : ""}&de=${de ? de : ""}`
        );
      } else {
        response = await api(localStorage.getItem("token")).get(
          `/client-management/sub-client?p=${p}&id=${id ? id : ""}&n=${
            n ? encodeURIComponent(n) : ""
          }&s=${s ? s : ""}&c=${c ? c?.split(",")[1] : ""}&pop=${
            pop?.split(",")[1] ? pop?.split(",")[1] : ""
          }&router=${router ? router : ""}&c_switch=${switche ? switche : ""}`
        );
      }
      if (response.status === 200) {
        dispatch({ type: LOADED });
        return response.data;
      }
    } catch (err) {
      /* =============Start========== */
      if (err?.response?.status === 422) {
        err.response.data.errors.map((item) => {
          dispatch({
            type: VALIDATION_ERROR,
            payload: [{ message: item.message, path: item.path[0] }],
          });
        });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
        localStorage.clear();
        history.push("/login");
      }
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response
          ? err.response.data.message
          : history.push("/opps"),
      });
      dispatch({ type: LOADED });
      /* ===========End============*/
    }
  };

export const getSubClientDetails = (client) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/client-management/sub-client/detail?sub_client=${client}`
    );
    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response?.data?.detail[0];
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const createSubClient = (data) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  try {
    const obj = {
      name: data?.name,
      id: data?.id,
      client: data?.client?.split(",")[1],
      address: data?.address,
      status: data?.status,
      district: data?.district,
      area: data?.area,
      contact_person: [
        {
          name: data?.name1,
          number: data?.number1,
          email: data?.email1,
          description: data?.description1,
        },
        {
          name: data?.name2,
          number: data?.number2,
          email: data?.email2,
          description: data?.description2,
        },
      ],
      bulk_email: data?.bulk_email,
      email: data?.email,
      bank_info: {
        atm_name: data?.atm_name,
        atm_no: data?.atm_no,
        branch_name: data?.branch_name,
        service_in: data?.service_in,
        media: data?.media,
        branch_code: data?.branch_code,
        carrier: data?.carrier,
      },
      link_from: data?.link_from,
      link_to: data?.link_to,
      connection_type: data?.connection_type,
      connection_description: data?.connection_description,
      pop: data?.pop?.split(",")[1],
      router: data?.router,
      switch: data?.switch,
      switch_port_no: data?.switch_port_no !== "" ? data?.switch_port_no : 0,
      vlanid: data?.vlanid,
      nated_from_ip: data?.nated_from_ip,
      ip_block: data?.ip_block,
      microtik_port: data?.microtik_port,
      mrtg: data?.mrtg,
      radio_ip: data?.radio_ip,
      sales_by: data?.sales_by,
      inst_date: data?.inst_date,
      end_date: data?.end_date,
      description: data?.description,
      unit_data: data?.unit_data ? data?.unit_data : 0,
      unit_data_band: data?.unit_data_band,
      internet: data?.internet !== "" ? data?.internet : 0,
      internet_band: data?.internet_band,
      youtube: data?.youtube !== "" ? data?.youtube : 0,
      youtube_band: data?.youtube_band,
      facebook: data?.facebook !== "" ? data?.facebook : 0,
      facebook_band: data?.facebook_band,
      bdix: data?.bdix !== "" ? data?.bdix : 0,
      bdix_band: data?.bdix_band,
      other: data?.other !== "" ? data?.other : 0,
      other_band_name: data?.other_band_name,
      other_band: data?.other_band,
      unit_quantity: data?.unit_quantity !== "" ? data?.unit_quantity : 0,
      contact_amount: data?.contact_amount !== "" ? data?.contact_amount : 0,
      vat_include: data?.vat_include,
      vat_rate: data?.vat_rate,
      rate_amount: data?.rate_amount !== "" ? data?.rate_amount : 0,
      vat_amount: data?.vat_amount !== "" ? data?.vat_amount : 0,
      bill_start_date: data?.bill_start_date,
      rate_effect_date: data?.rate_effect_date,
    };
    const response = await api(localStorage.getItem("token")).post(
      "/client-management/sub-client/create",
      { ...obj }
    );
    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Created successfully",
      });

      dispatch({ type: LOADED });
      return response.status;
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const updateSubClient = (data) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  try {
    const obj = {
      name: data?.name,
      sub_client: data?.id,
      address: data?.address,
      client: data?.client?.split(",")[1],
      status: data?.status,
      district: data?.district,
      area: data?.area,
      contact_person: [
        {
          name: data?.name1,
          number: data?.number1,
          email: data?.email1,
          description: data?.description1,
        },
        {
          name: data?.name2,
          number: data?.number2,
          email: data?.email2,
          description: data?.description2,
        },
      ],
      email: data?.email,
      bulk_email: data?.bulk_email,
      bank_info: {
        atm_name: data?.atm_name,
        atm_no: data?.atm_no,
        branch_name: data?.branch_name,
        service_in: data?.service_in,
        media: data?.media,
        branch_code: data?.branch_code,
        carrier: data?.carrier,
      },
      link_from: data?.link_from,
      link_to: data?.link_to,
      connection_type: data?.connection_type,
      connection_description: data?.connection_description,
      pop: data?.pop?.split(",")[1],
      router: data?.router,
      switch: data?.switch,
      switch_port_no: data?.switch_port_no !== "" ? data?.switch_port_no : 0,
      vlanid: data?.vlanid,
      nated_from_ip: data?.nated_from_ip,
      ip_block: data?.ip_block,
      microtik_port: data?.microtik_port,
      mrtg: data?.mrtg,
      radio_ip: data?.radio_ip,
      sales_by: data?.sales_by,
      inst_date: data?.inst_date,
      end_date: data?.end_date !== null ? data?.end_date : "",
      description: data?.description,
      remark: data?.remark,
    };
    const response = await api(localStorage.getItem("token")).patch(
      "/client-management/sub-client/update/detail",
      { ...obj }
    );
    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Updated successfully",
      });

      dispatch({ type: LOADED });
      return response.status;
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const updateSubClientBandwidth = (data) => async (dispatch) => {
  dispatch({ type: LOADEDING });

  try {
    const obj = {
      sub_client: data?.id,
      unit_data: data?.unit_data ? data?.unit_data : 0,
      unit_data_band: data?.unit_data_band,
      internet: data?.internet !== "" ? data?.internet : 0,
      internet_band: data?.internet_band,
      youtube: data?.youtube !== "" ? data?.youtube : 0,
      youtube_band: data?.youtube_band,
      facebook: data?.facebook !== "" ? data?.facebook : 0,
      facebook_band: data?.facebook_band,
      bdix: data?.bdix !== "" ? data?.bdix : 0,
      bdix_band: data?.bdix_band,
      other: data?.other !== "" ? data?.other : 0,
      other_band_name: data?.other_band_name,
      other_band: data?.other_band,
      unit_quantity: data?.unit_quantity,
      contact_amount: data?.contact_amount,
      vat_include: data?.vat_include,
      vat_rate: data?.vat_rate,
      rate_amount: data?.rate_amount,
      vat_amount: data?.vat_amount,
      bill_start_date: data?.bill_start_date,
      rate_effect_date: data?.rate_effect_date,
      remark: data?.remark,
    };
    const response = await api(localStorage.getItem("token")).patch(
      "/client-management/sub-client/update/bandwidth",
      { ...obj }
    );
    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Updated successfully",
      });

      dispatch({ type: LOADED });
      return response.status;
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const deleteSubClient = (client, data) => async (dispatch) => {
  dispatch({ type: LOADEDING });

  try {
    const obj = {
      sub_client: client,
      remark: data?.remark,
    };
    const response = await api(localStorage.getItem("token")).patch(
      "/client-management/sub-client/delete",
      { ...obj }
    );
    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Deleted successfully",
      });

      dispatch({ type: LOADED });
      return response.status;
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const subClientReportDownloader =
  (p, id, n, s, c, pop, router, switche, ds, de) => async (dispatch) => {
    dispatch({ type: LOADEDING });

    try {
      let response = null;

      if (ds !== null && de !== null) {
        response = await api(localStorage.getItem("token")).get(
          `/client-management/sub-client?p=${p}&id=${id ? id : ""}&n=${
            n ? n : ""
          }&s=${s ? s : ""}&c=${c ? c?.split(",")[1] : ""}&pop=${
            pop?.split(",")[1] ? pop?.split(",")[1] : ""
          }&router=${router ? router : ""}&c_switch=${
            switche ? switche : ""
          }&ds=${ds ? ds : ""}&de=${de ? de : ""}&d=${true}`,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            method: "GET",
            responseType: "blob",
          }
        );
      } else {
        response = await api(localStorage.getItem("token")).get(
          `/client-management/sub-client?p=${p}&id=${id ? id : ""}&n=${
            n ? n : ""
          }&s=${s ? s : ""}&c=${c ? c?.split(",")[1] : ""}&pop=${
            pop?.split(",")[1] ? pop?.split(",")[1] : ""
          }&router=${router ? router : ""}&c_switch=${
            switche ? switche : ""
          }&d=${true}`,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            method: "GET",
            responseType: "blob",
          }
        );
      }

      if (response.status === 200) {
        // FileDown
        dispatch({ type: LOADED });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `branch.xlsx`);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      }
    } catch (err) {
      if (err?.response?.status === 451) {
        history.push("/pending-verification");
      }
      /* =============Start========== */
      if (err?.response?.status === 422) {
        err.response.data.errors.map((item) => {
          dispatch({
            type: VALIDATION_ERROR,
            payload: [{ message: item.message, path: item.path[0] }],
          });
        });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
        localStorage.clear();
        history.push("/login");
      }

      if (err.message == "Network Error") {
        history.push("/notFound");
        return;
      }
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response
          ? err.response.data.message
          : history.push("/404"),
      });
      dispatch({ type: LOADED });
      /* ===========End============*/
    }
  };

export const bandwidthReportDownloader = (ds, de, c, d) => async (dispatch) => {
  console.log(c);
  dispatch({ type: LOADEDING });

  try {
    let response = null;

    if (!d) {
      response = await api(localStorage.getItem("token")).get(
        `/client-management/sub-client/bandwidth?ds=${ds ? ds : ""}&de=${
          de ? de : ""
        }&c=${c ? c?.split(",")[1] : ""}&d=${d}`
      );

      if (response.status === 200) {
        dispatch({ type: LOADED });
        return response?.data?.report[0];
      }
    } else {
      response = await api(localStorage.getItem("token")).get(
        `/client-management/sub-client/bandwidth?ds=${ds ? ds : ""}&de=${
          de ? de : ""
        }&c=${c ? c?.split(",")[1] : ""}&d=${d}`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          method: "GET",
          responseType: "blob",
        }
      );

      if (response.status === 200) {
        // FileDown
        dispatch({ type: LOADED });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `bandwidth.xlsx`);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      }
    }
  } catch (err) {
    if (err?.response?.status === 451) {
      history.push("/pending-verification");
    }
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }

    if (err.message == "Network Error") {
      history.push("/notFound");
      return;
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/404"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};
