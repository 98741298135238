import { Content } from 'antd/lib/layout/layout';
import React from 'react';
import { Statistic, Row, Col, Card } from 'antd';
import { LikeOutlined,ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { Line,Column, Radar, RadialBar, RingProgress, Rose, Funnel, Pie } from '@ant-design/charts';
import { connect } from 'react-redux';
import { clientDashboard } from '../../actions/clients';
import { ticketDashboard } from '../../actions/support';
import { popDashboard } from '../../actions/pop';
import { useEffect } from 'react';
import { useState } from 'react';
import './styles.css';
const Dashboard = ({  clientDashboard, ticketDashboard, popDashboard }) => {
    const [totalTicket, setTotalTicket] = useState(null)
    const [clientData, setClientData] = useState(null)
    const [popData, setPopData] = useState(null)
    const [access, setAccess] = useState(null)
    useEffect( async () => {

        if(JSON.parse(localStorage.getItem('access'))?.client_management !== 'n'){
            const cli =  await clientDashboard();
            setClientData(cli);
        }
        if(JSON.parse(localStorage.getItem('access'))?.pop_management !== 'n'){
            const pop =  await popDashboard();
            setPopData(pop);
        }
        if(JSON.parse(localStorage.getItem('access'))?.ticket_management !== 'n'){
            const res =  await ticketDashboard();
            setTotalTicket(res?.statistic[0]);
        }
    }, [])
    const createOption = (month, ticket) => {
        return {month, ticket};
      };
      const ticketMonthlyData = totalTicket ? 
      [... 
        totalTicket?.lastYearReport?.map((item, key) => createOption(
        item?.month, 
        item?.ticket,
        ))
      ] : [];
    // const data = [
    //     { month: '2023-06', value: 3 },
    //     { month: '1992', value: 4 },
    //     { month: '1993', value: 3.5 },
    //     { month: '1994', value: 5 },
    //     { month: '1995', value: 4.9 },
    //     { month: '1996', value: 6 },
    //     { month: '1997', value: 7 },
    //     { month: '1998', value: 9 },
    //     { month: '1999', value: 13 },
    //   ];
    // console.log(ticketData)
      const config = {
        data: ticketMonthlyData,
        height: 400,
        xField: 'month',
        yField: 'ticket',
        point: {
          size: 5,
          shape: 'diamond',
        },
      };
    return ( 
        <Content style={{ margin: '0 16px' }}>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
               
            
             
            {JSON.parse(localStorage.getItem('access'))?.client_management !== 'n' ?
                <>
                    <Row gutter={8} className='box'>
                        <Col span={24}> <h3>Clients</h3> </Col>
                        <Col span={6}>
                            <Statistic title="Total Client" value={clientData?.client[0]?.total_client} />
                        </Col>
                        <Col span={6}>
                            <Statistic title="Active Client" value={clientData?.client[0]?.total_active} />
                        </Col>
                        <Col span={6}>
                            <Statistic title="Inactive Client" value={clientData?.client[0]?.total_inactive} />
                        </Col>
                    </Row>
                
                    <br />
                    <Row gutter={8} className='box'>
                        <Col span={24}> <h3>Branches</h3> </Col>
                        <Col span={6}>
                        <Statistic title="Total Branch" value={clientData?.sub_client[0]?.total_sub_client} />
                        </Col>
                        <Col span={6}>
                        <Statistic title="Active Branch" value={clientData?.sub_client[0]?.total_active} />
                        </Col>
                        <Col span={6}>
                        <Statistic title="Inactive Branch" value={clientData?.sub_client[0]?.total_inactive} />
                        </Col>
                    </Row>
                </>
            : null}
            <br />
            {JSON.parse(localStorage.getItem('access'))?.pop_management !== 'n' ?
                <Row gutter={8} className='box'>
                    <Col span={24}> <h3>Devices</h3> </Col>
                    <Col span={6}>
                    <Statistic title="Total Pop" value={popData?.pop} />
                    </Col>
                    <Col span={6}>
                    <Statistic title="Total Router" value={popData?.router} />
                    </Col>
                    <Col span={6}>
                    <Statistic title="Total Switch" value={popData?.switch} />
                    </Col>
                </Row>
             : null}  
            <br />

            {JSON.parse(localStorage.getItem('access'))?.ticket_management !== 'n' ?
                <>
                    <Row gutter={8} className='box'>
                        <Col span={24}> <h3>Total Tickets</h3> </Col>
                        <Col span={6}>
                        <Statistic title="Ticket" value={totalTicket?.totalTicketStatus[0]?.total_ticket} />
                        </Col>
                        <Col span={6}>
                        <Statistic title="Active Ticket" value={totalTicket?.totalTicketStatus[0]?.total_active} />
                        </Col>
                        <Col span={6}>
                        <Statistic title="Ticket Completed" value={totalTicket?.totalTicketStatus[0]?.total_completed} />
                        </Col>
                        <Col span={6}>
                        <Statistic title="Half Done" value={totalTicket?.totalTicketStatus[0]?.total_half_done} />
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={8} className='box'>
                        <Col span={24}> <h3>Last Month Tickets</h3> </Col>
                        <Col span={6}>
                        <Statistic title="Ticket" value={totalTicket?.lastMonthStatus[0]?.total_ticket} />
                        </Col>
                        <Col span={6}>
                        <Statistic title="Active Ticket" value={totalTicket?.lastMonthStatus[0]?.total_active} />
                        </Col>
                        <Col span={6}>
                        <Statistic title="Ticket Completed" value={totalTicket?.lastMonthStatus[0]?.total_completed} />
                        </Col>
                        <Col span={6}>
                        <Statistic title="Half Done" value={totalTicket?.lastMonthStatus[0]?.total_half_done} />
                        </Col>
                    </Row>
                </>
            : null} 
            <br />

            <br />
            <Row gutter={8}>
                {/* <Col span={12}>
                    <Card>
                        <Line {...config} />
                    </Card>
                </Col> */}
                {/* <Col span={12}>
                    <Card>
                        <Column
                            {...config}
                            onReady={(plot) => {
                                plot.on('plot:click', (evt) => {
                                const { x, y } = evt;
                                const { xField } = plot.options;
                                const tooltipData = plot.chart.getTooltipItems({ x, y });
                                });
                            }}
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <Radar
                            {...config}
                            onReady={(plot) => {
                                plot.on('plot:click', (evt) => {
                                const { x, y } = evt;
                                const { xField } = plot.options;
                                const tooltipData = plot.chart.getTooltipItems({ x, y });
                                });
                            }}
                        />
                    </Card>
                </Col>
                
                <Col span={12}>
                    <Card>
                        <RadialBar
                            {...config}
                            onReady={(plot) => {
                                plot.on('plot:click', (evt) => {
                                const { x, y } = evt;
                                const { xField } = plot.options;
                                const tooltipData = plot.chart.getTooltipItems({ x, y });
                                });
                            }}
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <RingProgress
                            {...config}
                            onReady={(plot) => {
                                plot.on('plot:click', (evt) => {
                                const { x, y } = evt;
                                const { xField } = plot.options;
                                const tooltipData = plot.chart.getTooltipItems({ x, y });
                                });
                            }}
                        />
                    </Card>
                </Col>
                
                <Col span={12}>
                    <Card>
                        <Rose
                            {...config}
                            onReady={(plot) => {
                                plot.on('plot:click', (evt) => {
                                const { x, y } = evt;
                                const { xField } = plot.options;
                                const tooltipData = plot.chart.getTooltipItems({ x, y });
                                });
                            }}
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <Funnel
                            {...config}
                            onReady={(plot) => {
                                plot.on('plot:click', (evt) => {
                                const { x, y } = evt;
                                const { xField } = plot.options;
                                const tooltipData = plot.chart.getTooltipItems({ x, y });
                                });
                            }}
                        />
                    </Card>
                </Col>
                 */}
                
            </Row>
            
            <br />
            </div>
        </Content>
     );
}
 
export default connect(null, { clientDashboard, ticketDashboard, popDashboard })(Dashboard);