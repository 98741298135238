import api from "./apis";
import { ERROR_MESSAGE, LOADED, LOADEDING, SUCCESS_MESSAGE, VALIDATION_ERROR } from "./types";
import history from "../history";
import axios from "axios";

export const ticketDashboard = () => async (dispatch) => {
  dispatch({ type: LOADEDING });
  try {
    let response = null;
    response = await api(localStorage.getItem("token")).get(
      `/ticket-management/dashboard`
    );
    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const ticketHelper = () => async (dispatch) => {
  
  dispatch({type: LOADEDING});
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/ticket-management/helper"
    );
    
    dispatch({type: LOADED});
    return ( response.data );
    
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const allTickets = ( p, id, s, t, pt, u, c, sc, pop, ds, de) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  
  try {
    let response = null;
      if(ds !== null && de !== null){
        response = await api(localStorage.getItem("token")).get(
        `ticket-management/ticket?p=${p ? p : ''}&id=${id ? id : ''}&s=${s ? s : ''}&t=${t ? t : ''}&pt=${pt ? pt : ''}&u=${u ? u : ''}&c=${c ? c?.split(',')[1] : ''}&sc=${sc ? sc : ''}&pop=${pop ? pop : ''}&ds=${ds}&de=${de}`
      );
    } else {
      response = await api(localStorage.getItem("token")).get(
        `ticket-management/ticket?p=${p ? p : ''}&id=${id ? id : ''}&s=${s ? s : ''}&t=${t ? t : ''}&pt=${pt ? pt : ''}&u=${u ? u : ''}&c=${c ? c?.split(',')[1] : ''}&sc=${sc ? sc : ''}&pop=${pop ? pop : ''}`
      );
    }
    
    if (response.status === 200) {
      
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const createSupportTicket = (data) => async (dispatch) => {
  dispatch({ type: LOADEDING });

  try {
    // const obj = {
    //   email: data[0],
    //   authenticated: data[1],
    // };
    data.client = data.client.split(',-,')[1];
    data.sub_client = data.sub_client.split(',-,')[1];
    const response = await api(localStorage.getItem("token")).post(
      "/ticket-management/ticket/support/create",
      { ...data }
    );
    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Created successfully",
      });

      dispatch({ type: LOADED });
      return response.status;
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const createTransmissionTicket = (data) => async (dispatch) => {
  dispatch({ type: LOADEDING });

  try {
    // const obj = {
    //   email: data[0],
    //   authenticated: data[1],
    // };
    data.pop = data.pop.split(',-,')[1];
    if (data.router) data.router = data.router.split(',-,')[1];
    if(data.switch) data.switch = data.switch.split(',-,')[1];
    const response = await api(localStorage.getItem("token")).post(
      "/ticket-management/ticket/transmission/create",
      { ...data }
    );
    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Created successfully",
      });

      dispatch({ type: LOADED });
      return response.status;
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const supportHelper = () => async (dispatch) => {
  dispatch({ type: LOADEDING });
  
  try {
    let response = '';
    response = await api(localStorage.getItem("token")).get(
      `/ticket-management/ticket/support/helper`
    );
    
    if (response.status === 200) {
      
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const transmissionHelper = () => async (dispatch) => {
  dispatch({ type: LOADEDING });
  
  try {
    let response = null;
    response = await api(localStorage.getItem("token")).get(
      `/ticket-management/ticket/transmission/helper`
    );
    
    if (response.status === 200) {
      
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const supportHelperById = (id) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  
  try {
    let response = null;
    response = await api(localStorage.getItem("token")).get(
      `/ticket-management/ticket/support/helper?id=${id}`
    );
    
    if (response.status === 200) {
      
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const transmissionHelperById = (id) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  
  try {
    let response = null;
    response = await api(localStorage.getItem("token")).get(
      `/ticket-management/ticket/transmission/helper?id=${id}`
    );
    
    if (response.status === 200) {
      
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const supportPendingTicket = () => async (dispatch) => {
  dispatch({ type: LOADEDING });
  
  try {
    let response = null;
    response = await api(localStorage.getItem("token")).get(
      `/ticket-management/ticket/department/pending`
    );
    
    if (response.status === 200) {
      
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const transmissionPendingTicket = () => async (dispatch) => {
  dispatch({ type: LOADEDING });
  
  try {
    let response = null;
    response = await api(localStorage.getItem("token")).get(
      `/ticket-management/ticket/other/pending`
    );
    
    if (response.status === 200) {
      
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const ticketDetails = (id) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  
  try {
    let response = null;
    response = await api(localStorage.getItem("token")).get(
      `/ticket-management/ticket/detail?ticket=${id}`
    );
    
    if (response.status === 200) {
      
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const reOpenTicket = (id) => async (dispatch) => {
  dispatch({ type: LOADEDING });

  try {
    const response = await api(localStorage.getItem("token")).patch(
      "/ticket-management/ticket/reopen",
      { ticket: id }
    );
    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Claimed successfully",
      });

      dispatch({ type: LOADED });
      return response.status;
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const claimTicket = (id) => async (dispatch) => {
  dispatch({ type: LOADEDING });

  try {
    // const obj = {
    //   email: data[0],
    //   authenticated: data[1],
    // };
    const response = await api(localStorage.getItem("token")).patch(
      "/ticket-management/ticket/claim",
      { ticket: id }
    );
    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Claimed successfully",
      });

      dispatch({ type: LOADED });
      return response.status;
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const processingList = () => async (dispatch) => {
  dispatch({ type: LOADEDING });
  
  try {
    let response = null;
    response = await api(localStorage.getItem("token")).get(
      `/ticket-management/ticket/processing/list`
    );
    
    if (response.status === 200) {
      
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const updateTicket = (data) => async (dispatch) => {
  dispatch({ type: LOADEDING });

  try {
    // const obj = {
    //   email: data[0],
    //   authenticated: data[1],
    // };
    const response = await api(localStorage.getItem("token")).patch(
      "/ticket-management/ticket/update",
      { ...data }
    );
    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Created successfully",
      });

      dispatch({ type: LOADED });
      return response.status;
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const todayTicket = () => async (dispatch) => {
  dispatch({ type: LOADEDING });
  
  try {
    let response = null;
    response = await api(localStorage.getItem("token")).get(
      `/ticket-management/ticket/today/completed`
    );
    
    if (response.status === 200) {
      
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};


export const ticketReportDownloader = (p, id, s, t, pt, u, c, sc, pop, ds, de, d) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  
  try{
    let response = null;
    if(ds !== null && de !== null){
      response = await api(localStorage.getItem("token")).get(
      `ticket-management/ticket?p=${p ? p : ''}&id=${id ? id : ''}&s=${s ? s : ''}&t=${t ? t : ''}&pt=${pt ? pt : ''}&u=${u ? u : ''}&c=${c ? c?.split(',')[1] : ''}&sc=${sc ? sc : ''}&pop=${pop ? pop : ''}&ds=${ds}&de=${de}&d=${true}`,
      {
        headers: {
          'authorization': `Bearer ${localStorage.getItem("token")}`
          },
          method: 'GET',
          responseType: 'blob',
        }
      );
  } else {
    response = await api(localStorage.getItem("token")).get(
      `ticket-management/ticket?p=${p ? p : ''}&id=${id ? id : ''}&s=${s ? s : ''}&t=${t ? t : ''}&pt=${pt ? pt : ''}&u=${u ? u : ''}&c=${c ? c?.split(',')[1] : ''}&sc=${sc ? sc : ''}&pop=${pop ? pop : ''}&d=${true}`,
      {
        headers: {
          'authorization': `Bearer ${localStorage.getItem("token")}`
          },
          method: 'GET',
          responseType: 'blob',
        }
      );
  }
      // const response = await api(localStorage.getItem("token")).get(`/client-management/client?p=${p}&id=${id ? id : ""}&n=${n ? n : ""}&s=${s ? s : ""}&d=${true}`,
      //   {
      //     headers: {
      //       'authorization': `Bearer ${localStorage.getItem("token")}`
      //       },
      //       method: 'GET',
      //       responseType: 'blob',
      //     }
      //   );
        
    if(response.status === 200 ) {
      // FileDown
      dispatch({ type: LOADED });
      const url = window.URL.createObjectURL(new Blob([response.data]),);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `tickets.xlsx`,
      );
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
  }
  catch( err ){
      if(err?.response?.status === 451)
        {
          history.push('/pending-verification')
        }
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        
        if(err.message == 'Network Error')
        {
            history.push('/notFound')
            return
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/404') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}


export const updateTicketDept = (ticket, data) => async (dispatch) => {
  dispatch({ type: LOADEDING });

  try {
    const obj = {
      ticket: ticket,
      department: data?.department,
    };
    const response = await api(localStorage.getItem("token")).patch(
      "/ticket-management/ticket/department/update",
      { ...obj }
    );
    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Created successfully",
      });

      dispatch({ type: LOADED });
      return response.status;
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};