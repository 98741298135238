import React, { Component } from 'react'
//import 'antd/dist/antd.css';
import { Table, Input, Button, Space, PageHeader, Popconfirm, Breadcrumb,Tag, Drawer, Modal, Form, Pagination, Row, Col, Tooltip, Select    } from 'antd';
import Highlighter from 'react-highlight-words';
import { CheckCircleOutlined, DeleteOutlined, EditOutlined, EyeOutlined, PlusCircleFilled, RollbackOutlined, SearchOutlined,SettingOutlined,StopOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { getSwitcher, createSwitch, updateSwitch, deleteSwitch, getSwitchDetails } from "../../actions/switch";
import { Link } from 'react-router-dom';
import history from '../../history';
import { connect } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { renderInput, renderSelect, renderTextField } from '../../component/form/form';
import { getPops } from '../../actions/pop';
// import { renderTextField } from '../../../component/form/form';
// import Remarks from '../../../component/remark';
// import ModalForm from './modalForm';
// import EditModalForm from './editModalForm';
const Search = Input.Search;
const { Option } = Select;
const showTotal = (total) => `Total ${total} items`;

const SwitchList = ({ getSwitcher, updateSwitch, deleteSwitch, getSwitchDetails, createSwitch, getPops }) => {
  const [ID, setID] = useState('')
  const [sId, setSId] = useState('')
  const [popId, setPopId] = useState(null)
  const [page, setPage] = useState(1)
  const [name, setName] = useState('')
  const [use, setUse] = useState('')
  const [allPops, setallPops] = useState(null)
  const [data, setData] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);  
  const [access, setAccess] = useState('');
  const [form] = Form.useForm();
  const [cform] = Form.useForm();

  const createOption = (value, label) => {
    return {value, label};
  };

  const status = [
    {value: 'used', label: 'In use'},
    {value: 'unused', label: 'Unused'},
  ]
  const getData = async () => {
    const res = await getSwitcher(page, sId, name, use, popId);
    setData(res);
    const pop = await getPops('', '', '', true);
    const option = pop?.list ?
    [... 
      pop?.list?.map((item, key) => createOption(
        item.name + ',' + item.id, 
      item.name,
      ))
    ]
    : [];
    setallPops(option)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    getData()
    setAccess(JSON.parse(localStorage.getItem('access'))?.pop_management);
  }, [])

  const showModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleOk = () => {
    setIsCreateModalOpen(false);
  };

  const handleCancel = () => {
    setIsCreateModalOpen(false);
  };

  const handleEdit = async (item) => {
    setID(item?._id)
    const res =  await getSwitchDetails(item?.id);
    if(res) {
      setIsEditModalOpen(true);
      form.setFieldsValue({
        id: res?.id,
        pop: res?.pop?.name + ',' + res?.pop?.id,
        name: res?.name,
        no: res?.no,
        description: res?.description,
      });
    }
    
  }
  
  const handleDelete = async (item) => {
    const res =  await deleteSwitch(item?.id, 'client-type');
    if(res === 201){
      getData();
    };
  }
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Search
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
      setSearchText('');
  };

  const createData = (id, pop_id, pop_name, name, switch_id, action) => {
    return {id, pop_id, pop_name, name, switch_id, action};
  };

  const columns = [
    {
      title: 'SL',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
    },
    {
      title: 'POP ID',
      dataIndex: 'pop_id',
      key: 'pop_id',
      width: '20%',
      ...getColumnSearchProps('pop_id'),
    },
    {
      title: 'POP Name',
      dataIndex: 'pop_name',
      key: 'pop_name',
      width: '20%',
      ...getColumnSearchProps('pop_name'),
    },
    {
      title: 'Switch Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'IP',
      dataIndex: 'switch_id',
      key: 'switch_id',
      width: '20%',
      ...getColumnSearchProps('switch_id'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '20%',
    },
  ];
  const rows = [
    data
      ? data?.list?.map((item, key) => createData(
        key + 1,
        item?.pop?.id,
        item?.pop?.name,
        item?.name,
        item?.id,
        <>
          {access === 'a' ? 
            <>
              <Button size="small" type="primary" icon={<EditOutlined  />} onClick={() => handleEdit(item)}>Edit</Button> &nbsp;
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText="Yes"
                cancelText="No"
                type="danger"
                onConfirm={() => handleDelete(item)}
              >
                <Button size="small" type="danger" icon={<DeleteOutlined  />} >Delete</Button> &nbsp;
              </Popconfirm>
            </>
          : null}
          {access === 'w' ? 
            <>
              <Button size="small" type="primary" icon={<EditOutlined  />} onClick={() => handleEdit(item)}>Edit</Button> &nbsp;
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText="Yes"
                cancelText="No"
                type="danger"
                onConfirm={() => handleDelete(item)}
              >
                <Button size="small" type="danger" icon={<DeleteOutlined  />} >Delete</Button> &nbsp;
              </Popconfirm>
            </>
          : null}
          {access === 'r' ? 
            <>
              ---------
            </>
          : null}
          {/* <Button size="small" type="danger" icon={<DeleteOutlined  />} onClick={() => handleDelete(item)}>Delete</Button> &nbsp; */}
        </>
        ))
      : "",
  ];

  const onFinish = async (values) => {
    const res = await createSwitch(values);
    if(res ===  201){
      getData();
      setIsCreateModalOpen(false);
      cform.resetFields();
    }
  };

  const onFinishEdit = async (values) => {
    const res = await updateSwitch(values, ID);
    if(res ===  201){
      getData();
      setIsEditModalOpen(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };
  
  const handlePagination = async (e) => {
    setPage(e);
    const res = await getSwitcher(e, sId, name, use, popId)
    setData(res);
  }
  
  const handleApiSearch = async () => {
    const res = await getSwitcher(page, sId, name, use, popId)
    setData(res);
  }
  const handleApiResetSearch = async () => {
    setPage('');
    setSId('');
    setName('');
    setUse('');
    setPopId(null);
    const res = await getSwitcher('', '', '', '', '')
    setData(res);
  }

  return ( 
    <Content style={{ margin: '0 16px' }}>

      <Breadcrumb style={{padding:"10px 0px"}}>
        <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Switch List</Breadcrumb.Item>
      </Breadcrumb>

      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Switch List"
        subTitle="POP Management"
        extra={[
          <Button key="1"  type="primary" icon={<PlusCircleFilled />} onClick = { showModal}>Create New
          </Button>,
        ]}
      >
      </PageHeader>

      <Row>
        <Col xs={10} sm={10} md={6} lg={6} xl={6}>
            <Input value={sId} placeholder="Switch IP" allowClear style={{width:'99%'}} onChange={(e)=>setSId(e.target.value)} />
        </Col>
        
        <Col xs={10} sm={10} md={6} lg={6} xl={6}>
            <Input value={name} placeholder="Switch Name" allowClear style={{width:'99%'}} onChange={(e)=> setName(e.target.value)} />
        </Col>

        <Col xs={10} sm={10} md={6} lg={6} xl={6}>
          <Select
            placeholder="Select Pop"
            size="middle"
            allowClear
            showSearch
            style={{width:'99%'}} onChange={(e)=> setPopId(e)} 
            value={popId}
          >
            <Option value=''>None</Option>
              {allPops?.map(item => (
                  <Option value={item.value}>{item.label}</Option>
              ))}
          </Select>
        </Col>
        
        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
          <Tooltip title="search" onClick={handleApiSearch}>
            <Button type="primary" icon={<SearchOutlined />} >Search </Button>
          </Tooltip>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
            
            <Tooltip title="search" onClick={handleApiResetSearch}>
                <Button type="primary" icon={<RollbackOutlined />}>Reset </Button>
            </Tooltip>
        </Col>
      </Row>
      <Table size="small" columns={columns} pagination={false} dataSource={rows[0]} scroll={{ x: 1000 }} sticky/>
      <br />

      <Pagination showTotal={showTotal} pageSizeOptions={[20]} current={page} pageSize={20} total={data?.count} onChange={(e) => { handlePagination(e); }} />
      
      <Modal 
        title="Add Switch" 
        open={isCreateModalOpen} 
        onOk={handleOk} 
        onCancel={handleCancel} 
        width={800}
        footer={null}
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={cform}
        >
          {renderInput('id', 'IP', [
            { required: true, message: 'This field is required!' },
          ], 'large')
          }
          {renderInput('name', 'Switch Name', [
              { required: true, message: 'This field is required!' },
            ], 'large')
          }
          {renderSelect('pop', 'POP', [
              { required: true, message: 'This field is required!' },
            ],' ', allPops, '', 'large')
          }
          {renderInput('no', 'Number', [
            { required: true, message: 'This field is required!' },
          ], 'large')
          }
          {/* {renderInput('pop', 'POP', [
              { required: true, message: 'This field is required!' },
            ], 'large')
          } */}
          {renderTextField('description', 'Description', [
              { required: false, message: 'This field is required!' },
            ], 'small')
          }
           
          <Form.Item {...tailLayout}> 
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
                
        </Form>
      </Modal>
      
      <Modal 
        title="Edit Switch" 
        open={isEditModalOpen} 
        onOk={() => setIsEditModalOpen(false)} 
        onCancel={() => setIsEditModalOpen(false)} 
        width={800}
        footer={null}
        form={form}
      >
        <Form
          {...layout}
          name="Edit "
          initialValues={{ remember: true }}
          onFinish={onFinishEdit}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          {renderInput('id', 'IP', [
            { required: true, message: 'This field is required!' },
          ], 'large')
          }
          {renderInput('name', 'Switch Name', [
              { required: true, message: 'This field is required!' },
            ], 'large')
          }
          {renderSelect('pop', 'POP', [
              { required: true, message: 'This field is required!' },
            ],' ', allPops, '', 'large')
          }
          {/* {renderInput('pop', 'POP', [
              { required: true, message: 'This field is required!' },
            ], 'large')
          } */}
          {renderInput('no', 'Number', [
            { required: true, message: 'This field is required!' },
          ], 'large')
          }
          {renderTextField('description', 'Description', [
              { required: false, message: 'This field is required!' },
            ], 'small')
          }
          {renderTextField('remark', 'Remark', [
              { required: false, message: 'This field is required!' },
            ], 'small')
          }
          <Form.Item {...tailLayout}> 
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
                
        </Form>
      </Modal>
      
    </Content>
   );
}
 
export default connect(null, {getSwitcher, createSwitch, updateSwitch,deleteSwitch, getSwitchDetails, getPops})(SwitchList);