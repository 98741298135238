import React, { useEffect,useRef } from "react";
import {
  Descriptions,
  Breadcrumb,
  PageHeader,
  Tag,
  Empty,
  Timeline,
  Button,
} from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import { ticketDetails } from "../../actions/support";

import { Title, Wrapper } from "./detailStyle";
import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import 'jspdf-autotable'
import jsPDF from 'jspdf';
import ReactToPrint from 'react-to-print';
// import * as FileSaver from 'file-saver';
import Remarks from "../../component/remark";
import { CheckSquareFilled, WarningOutlined } from "@ant-design/icons";
import { TicketToPrint } from "./ticketToPrint";
const TicketDetails = ({ location, ticketDetails }) => {
  const [data, setData] = useState(null);

  const componentRef = useRef();

  useEffect(async () => {
    //window.scrollTo(0, 0);
    const id = new URLSearchParams(location.search).get("id");
    const res = await ticketDetails(id);
    setData(res?.detail);
  }, []);

  const handleRefresh = async () => {
    const res = await ticketDetails(data?.email);
    setData(res);
  };

  const makeLocalTime = (date) => {
    let hours = date.split("T")[1].split(":")[0];
    let minutes = date.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours == 12) {
      am = false;
    } else if (hours == 0) {
      hours = 12;
    }
    return (
      date.split("T")[0] +
      "," +
      hours +
      ":" +
      minutes +
      " " +
      (am ? "A.M" : "P.M")
    );
  };

  const capitalizer = s => 
    s.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')
  const text = (
    <div id="contentToPrint">
    <h1>Hello</h1>
    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita nisi assumenda cumque pariatur excep</p>
    <br />
    <h1>Hello2</h1>
    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita nisi assumenda cumque pariatur excep</p>
    </div>
  )
    const pdfGenerate = async (id) => {
        // text;
        const elementHTML = document.querySelector("#contentToPrint");
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
    
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(10);
        const title = data?.id 
        const headers = [["Order Time"]];
    
        const datas = [data?.created_at ? makeLocalTime(data?.created_at) : null];
        // const data = assigned?.report?.map(elt=> [ makeLocalTime(elt.created_at),elt.order_id, elt.recipient, elt.shop, elt.amount_to_collect, "D | R | H | P" ]);
      
        let content = {
          startY: 80,
          head: headers,
          body: datas,
          theme: 'plain'
        };
        // doc.html= text;
        // doc.text(10, 10, text);
        // doc.autoTable(content);
        // doc.save(`demo.pdf`)
        
          doc.html(elementHTML, {
            callback: function(doc) {
                // Save the PDF
                doc.save('document-html.pdf');
            },
            margin: [10, 10, 10, 10],
            autoPaging: 'text',
            x: 0,
            y: 0,
            width: 190, //target width in the PDF document
            windowWidth: 675 //window width in CSS pixels
  });
      }
  return (
    <Content style={{ margin: "0 16px" }}>
      <Breadcrumb style={{ padding: "10px 0px" }}>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Ticket Details</Breadcrumb.Item>
      </Breadcrumb>
      {/* <div style={{ display: ''}}>
        <div id="contentToPrint">
        {data ? 
          <Wrapper>
            <h2>
              Ticket No : {data?.id}
            </h2>
            <h4>Open Date & Time: {data?.created_at ? makeLocalTime(data?.created_at) : null}</h4>
            {data?.end_time ?
              <h4>Close Date & Time: {makeLocalTime(data?.end_time)}</h4>
            : null}
          {data?.status === 'completed' ? 
            (
              <Tag color="#2ecc71">Solved</Tag>
            ) 
            : 
            (
              <Tag color="#3498db">{capitalizer(data?.status)}</Tag>
            )
          }
            <Tag color="#cd2df5">
              Priority: {data?.priority}
            </Tag>
            <br />
            <br />
            <Title style={{ backgroundColor: '#fff', color: '#000', borderBottom: '1px solid grey', fontSize: '14' }}>Basic Information</Title>
            <Descriptions size="middle">
                  <Descriptions.Item label="Ticket No">
                    <strong>{data?.id}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="Open Date & Time">
                    <strong>{data?.start_time ? makeLocalTime(data?.start_time) : null}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="Close Date & Time">
                    <strong>{data?.end_time ? makeLocalTime(data?.end_time) : null}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="Create By">
                    <strong>{data?.creator?.name}</strong>
                  </Descriptions.Item>
            </Descriptions>
            {data?.client ?
              <>
                <Title style={{ backgroundColor: '#fff', color: '#000', borderBottom: '1px solid grey', fontSize: '14' }}>Client Information</Title>
                  <Descriptions size="middle">
                      <Descriptions.Item label="Client">
                        <strong>{data?.client?.name}</strong>
                      </Descriptions.Item>
                      <Descriptions.Item label="Branch">
                        <strong>{data?.sub_client?.name}</strong>
                      </Descriptions.Item>
                      <Descriptions.Item label="Contact Person">
                        <strong>{data?.contact_person}</strong>
                      </Descriptions.Item>
                      <Descriptions.Item label="Mobile">
                        <strong>{data?.mobile_number}</strong>
                      </Descriptions.Item>
                  </Descriptions>
              </>
            : null}
            {data?.pop ?
              <>
                <Title style={{ backgroundColor: '#fff', color: '#000', borderBottom: '1px solid grey', fontSize: '14' }}>POP Information</Title>
                  <Descriptions size="middle">
                    <Descriptions.Item label="Pop">
                      <strong>{data?.pop?.id} ({data?.pop?.name})</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="Router">
                      <strong>{data?.router?.id} ({data?.router?.name})</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="Switch">
                      <strong>{data?.switch?.id} ({data?.switch?.name})</strong>
                    </Descriptions.Item>
                  </Descriptions>
              </>
            : null}
              
            <Title style={{ backgroundColor: '#fff', color: '#000', borderBottom: '1px solid grey', fontSize: '14' }}>Ticket Details</Title>
              <Descriptions size="middle">
                <Descriptions.Item label="Department">
                  <strong>{capitalizer(data?.type)}</strong>
                </Descriptions.Item>
                <Descriptions.Item label="Problem Type">
                  <strong>{data?.problem_type}</strong>
                </Descriptions.Item>
                <Descriptions.Item label="Subject">
                  <strong>{data?.subject}</strong>
                </Descriptions.Item>
                <Descriptions.Item label="Created by">
                  <strong>{data?.creator?.name}</strong>
                </Descriptions.Item>
                <Descriptions.Item label="">
                  <strong></strong>
                </Descriptions.Item>
                <Descriptions.Item label="Problem Detail:">
                  <strong>{data?.description}</strong>
                </Descriptions.Item>
              </Descriptions>
            <Title style={{ backgroundColor: '#fff', color: '#000', borderBottom: '1px solid grey', fontSize: '14' }}>Ticket Details</Title>
            <Descriptions size="middle">
              <Descriptions.Item label="Department">
                <strong>{capitalizer(data?.type)}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Problem Type">
                <strong>{data?.problem_type}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Subject">
                <strong>{data?.subject}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Created by">
                <strong>{data?.creator?.name}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="">
                <strong></strong>
              </Descriptions.Item>
              <Descriptions.Item label="Problem Detail:">
                <strong>{data?.description}</strong>
              </Descriptions.Item>
            </Descriptions>

            <Title>Logs</Title>
            <br />
            {data?.logs ? (
              <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <tr>
                  <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>Date Time</th>
                  <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>Name</th>
                  <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>Action</th>
                  <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>Status</th>
                </tr>
                {data.logs.map((item, index) => (
                  <tr>
                    <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{item?.created_at ? makeLocalTime(item?.created_at) : null}</td>
                    <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{item?.admin?.name}</td>
                    <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{item?.message}</td>
                    <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd'}}>{item?.status}</td>
                  </tr>
                ))}

              </table>
              // <Timeline mode="left">
              //   {data.logs.map((item, index) => (
              //     <Timeline.Item
              //       label={makeLocalTime(item?.created_at)}
              //       keys={index}
              //     >
              //       {item?.message}
              //     </Timeline.Item>
              //   ))}
              // </Timeline>
            ) : (
              <Empty />
            )}
            <br />
            <br />
          </Wrapper>
        : null}
        </div>
      </div> */}
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Ticket Details"
        subTitle="Ticket Management"
        // extra={
        //   <Button onClick={pdfGenerate}>Download</Button>
        // }
      ></PageHeader>
       <div style={{}}>
        <ReactToPrint
          trigger={() => <Button type="primary">Print this out!</Button>}
          content={() => componentRef.current}
        />
      </div>
        <TicketToPrint data={data} ref={componentRef} />
     
    </Content>
  );
};

export default connect(null, { ticketDetails })(
  TicketDetails
);
