 // Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
  // the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)

import React, { useEffect, useState } from "react";
import {
    Descriptions,
    Breadcrumb,
    PageHeader,
    Tag,
    Empty,
    Timeline,
    Button,
    Popover,
  } from "antd";
  
import { Title, Wrapper } from "./detailStyle";
import { Content } from "antd/lib/layout/layout";
  export const ClientToprint = React.forwardRef((props, ref) => {
    // const [data, setData] = useState(null);
    // console.log(props)
    // useEffect(() => {
    //     setData(null);
    //   setData(props.data);
    // }, [])
     const {data} = props;
  const makeLocalTime = (date) => {
    let hours = date.split("T")[1].split(":")[0];
    let minutes = date.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours == 12) {
      am = false;
    } else if (hours == 0) {
      hours = 12;
    }
    return (
      date.split("T")[0] +
      "," +
      hours +
      ":" +
      minutes +
      " " +
      (am ? "A.M" : "P.M")
    );
  };

  
  const capitalizer = s => 
    s.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')
  
    return (
            <Wrapper ref={ref}>
                <h2>
                Name : {data?.name}
                </h2>
                {
                data?.status == 'active' ? 
                (
                    <Tag color="#6fcf20">Active</Tag>
                ) 
                : 
                data?.status == 'inactive' ? 
                (
                    <Tag color="#e96710">Inactive</Tag>
                ) 
                : 
                data?.status == 'blocked' ? 
                (
                    <Tag color="#e74c3c">Blocked</Tag>
                ) 
                : 
                (
                    <Tag color="#f1c40f">Hold</Tag>
                )
                }

                <Tag color="#cd2df5">
                {data?.created_at ? makeLocalTime(data?.created_at) : null}
                </Tag>
                <br />
                <br />
                {data ? (
                <>
                    <Title>General Information</Title>
                    <Descriptions>
                    <Descriptions.Item label="ID">
                        <strong>{data?.id}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="CLIENT NAME">
                        <strong>{data?.name}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="EMAIL">
                        <strong>{data?.email}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="ADDRESS">
                        <strong>{data?.address}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="DISTRICT">
                        <strong>{data?.district}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="AREA">
                        <strong>{data?.area}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="BUSINESS TYPE">
                        <strong>{data?.business_type}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="WEB ADDRESS">
                        <strong>{data?.web_address}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="BULK EMAIL">
                        <strong>{data?.bulk_email}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="DESCRIPTION">
                        <strong>{data?.description}</strong>
                    </Descriptions.Item>
                    </Descriptions>

                
                    <Title>Bill Information</Title>
                    <Descriptions>
                    <Descriptions.Item label="BILLING TYPE">
                        <strong>{capitalizer(data?.billing_type)}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="COLLECTED BY">
                        <strong>{data?.bill_collection_by}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="SOLD BY">
                        <strong>{data?.sale_by}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="DISCOUNT BY">
                        <strong>{data?.discount}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="ADDITIONAL AMOUNT">
                        <strong>{data?.addition_amount}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="INVOICE TYPE">
                        <strong>{data?.invoice_type}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="VAT STATUS">
                        <strong>{data?.vat_status == 'yes' ? 'Yes' : 'No'}</strong>
                    </Descriptions.Item>
                    </Descriptions>
                    
                    <Title>Contact Information</Title>
                    {data?.contact_person?.map((item, key) => (
                    <>
                    <h3>Contact Person {key + 1}</h3>
                    <hr />
                    <Descriptions>
                    <Descriptions.Item label="NAME">
                        <strong>{item?.name}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="EMAIL">
                        <strong>{item?.email}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="PHONE">
                        <strong>{item?.number}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="DESCRIPTION">
                        <strong>{item?.description}</strong>
                    </Descriptions.Item>
                    </Descriptions>
                    </>
                    ))}
                    
                    <br />
                    {data?.logs ? (
                    <Timeline mode="left">
                        {data.logs.map((item, index) => (
                        <Timeline.Item
                            label={makeLocalTime(item?.created_at)}
                            keys={index}
                        >
                            {item?.message}
                        </Timeline.Item>
                        ))}
                    </Timeline>
                    ) : (
                    <Empty />
                    )}
                </>
                ) : null}
                <br />
                <br />
            </Wrapper>
    );
  });