import React, { Component } from 'react'
//import 'antd/dist/antd.css';
import { Table, Input, Button, Space, PageHeader, Popconfirm, Breadcrumb,Tag, Drawer, Modal, Form, Pagination    } from 'antd';
import Highlighter from 'react-highlight-words';
import { CheckCircleOutlined, DeleteOutlined, EditOutlined, EyeOutlined, PlusCircleFilled, SearchOutlined,SettingOutlined,StopOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { addVendor, UpdateVendor, deleteApi, getVendor } from "../../actions/setting";
import { Link } from 'react-router-dom';
import history from '../../history';
import { connect } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { renderInput } from '../../component/form/form';
// import { renderTextField } from '../../../component/form/form';
// import Remarks from '../../../component/remark';
// import ModalForm from './modalForm';
// import EditModalForm from './editModalForm';
const Search = Input.Search;
const Select = Input.Search;
const showTotal = (total) => `Total ${total} items`;

const Vendor = ({ getVendor, addVendor, UpdateVendor, deleteApi }) => {
  const [ID, setID] = useState('')
  const [page, setPage] = useState(1)
  const [data, setData] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [cform] = Form.useForm();

  const getData = async () => {
    const res = await getVendor(page)
    setData(res);
  }
  
  useEffect(() => {
    //window.scrollTo(0, 0);
    getData()
  }, [])

  const showModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleOk = () => {
    setIsCreateModalOpen(false);
  };

  const handleCancel = () => {
    setIsCreateModalOpen(false);
  };

  const handleEdit = async (item) => {
    setID(item?.id)
    setIsEditModalOpen(true);
    form.setFieldsValue({
      name: item?.name,
      contact_name: item?.contact_name,
      mobile_number: item?.mobile_number,
      email: item?.email,
      web: item?.web,
    });
  }
  
  const handleDelete = async (item) => {
    const res =  await deleteApi(item?.id, 'vendor');
    if(res === 201){
      getData();
    };
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Search
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
      setSearchText('');
  };

  const createData = (id, name, contact_name, mobile_number, email, web, action) => {
    return {id, name, contact_name, mobile_number, email, web, action};
  };

  const columns = [
    {
      title: 'SL',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
      ...getColumnSearchProps('id'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '60%',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Contact Name',
      dataIndex: 'contact_name',
      key: 'contact_name',
      width: '60%',
      ...getColumnSearchProps('contact_name'),
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobile_number',
      key: 'mobile_number',
      width: '60%',
      ...getColumnSearchProps('mobile_number'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '60%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Web',
      dataIndex: 'web',
      key: 'web',
      width: '60%',
      ...getColumnSearchProps('web'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '20%',
    },
  ];

  const rows = [
    data
      ? data?.list?.map((item, key) => createData(
        key + 1, 
        item.name,
        item.contact_name,
        item.mobile_number,
        item.email,
        item.web,
        <>
          <Button size="small" type="primary" icon={<EditOutlined  />} onClick={() => handleEdit(item)}>Edit</Button> &nbsp;
          <Button size="small" type="danger" icon={<DeleteOutlined  />} onClick={() => handleDelete(item)}>Delete</Button> &nbsp;
        </>
        ))
      : "",
  ];

  const onFinish = async (values) => {
    const res = await addVendor(values, ID);
    if(res ===  201){
      getData();
      setIsCreateModalOpen(false);
      cform.resetFields();
    }
  };

  const onFinishEdit = async (values) => {
    const res = await UpdateVendor(values, ID);
    if(res ===  201){
      getData();
      setIsEditModalOpen(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };

  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const handlePagination = async (e) => {
    setPage(e);
    const res = await getVendor(e)
    setData(res);
  }

  return ( 
    <Content style={{ margin: '0 16px' }}>

      <Breadcrumb style={{padding:"10px 0px"}}>
        <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Priority Type</Breadcrumb.Item>
      </Breadcrumb>

      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Priority Type"
        subTitle="Setting Management"
        extra={[
          <Button key="1"  type="primary" icon={<PlusCircleFilled />} onClick = { showModal}>Create New
          </Button>,
        ]}
      >
      </PageHeader>

      <Table size='small' columns={columns} pagination={false} dataSource={rows[0]} scroll={{ x: 1000 ,y: 400 }} sticky/>
      <br />
      <Pagination size='small' showTotal={showTotal} pageSizeOptions={[20]} current={page} pageSize="20" total={data?.count} onChange={(e) => { handlePagination(e); }} />
      
      <Modal 
        title="Add Priority Type" 
        open={isCreateModalOpen} 
        onOk={handleOk} 
        onCancel={handleCancel} 
        width={800}
        footer={null}
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={cform}
        >
              
          {renderInput('name', 'Vendor Name', [
              { required: true, message: 'This field is required!' },
            ])
          }
          {renderInput('contact_name', 'Contact Name', [
              { required: true, message: 'This field is required!' },
            ])
          }
          {renderInput('mobile_number', 'Mobile Number', [
              { required: true, message: 'This field is required!' },
            ])
          }
          {renderInput('email', 'Email', [
              { required: true, message: 'This field is required!' },
            ])
          }
          {renderInput('web', 'Web', [
              { required: true, message: 'This field is required!' },
            ])
          }
          <Form.Item {...tailLayout}> 
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
                
        </Form>
      </Modal>
      
      <Modal 
        title="Edit Priority Type" 
        open={isEditModalOpen} 
        onOk={() => setIsEditModalOpen(false)} 
        onCancel={() => setIsEditModalOpen(false)} 
        width={800}
        footer={null}
      >
        <Form
          {...layout}
          name="Edit "
          initialValues={{ remember: true }}
          onFinish={onFinishEdit}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          
          {renderInput('name', 'Vendor Name', [
              { required: true, message: 'This field is required!' },
            ])
          }
          {renderInput('contact_name', 'Contact Name', [
              { required: true, message: 'This field is required!' },
            ])
          }
          {renderInput('mobile_number', 'Mobile Number', [
              { required: true, message: 'This field is required!' },
            ])
          }
          {renderInput('email', 'Email', [
              { required: true, message: 'This field is required!' },
            ])
          }
          {renderInput('web', 'Web', [
              { required: true, message: 'This field is required!' },
            ])
          }
          <Form.Item {...tailLayout}> 
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
                
        </Form>
      </Modal>
      
    </Content>
   );
}
 
export default connect(null, {getVendor, addVendor, UpdateVendor, deleteApi})(Vendor);