import api from "./apis";
import {
  ERROR_MESSAGE,
  LOADED,
  LOADEDING,
  SUCCESS_MESSAGE,
  VALIDATION_ERROR,
} from "./types";
import history from "../history";

export const clientDashboard = () => async (dispatch) => {
  dispatch({ type: LOADEDING });
  try {
    let response = null;
    response = await api(localStorage.getItem("token")).get(
      `/client-management/dashboard`
    );
    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const getClients = (p, id, n, s) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  try {
    let response = null;
    response = await api(localStorage.getItem("token")).get(
      `/client-management/client?p=${p}&id=${id ? id : ""}&n=${n ? n : ""}&s=${s ? s : ""}`
    );
    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const getClientDetails = (client) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/client-management/client/detail?client=${client}`
    );
    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data.detail;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const createClient = (data) => async (dispatch) => {
  dispatch({ type: LOADEDING });

  try {
    const obj = {
      name: data?.name,
      id: data?.id,
      address: data?.address,
      description: data?.description,
      district: data?.district,
      area: data?.area,
      contact_person: [
        {
          name: data?.name1,
          number: data?.number1,
          email: data?.email1,
          description: data?.description1,
        },
        {
          name: data?.name2,
          number: data?.number2,
          email: data?.email2,
          description: data?.description2,
        },
      ],
      email: data?.email,
      business_type: data?.business_type,
      invoice_type: data?.invoice_type,
      bill_collection_by: data?.bill_collection_by,
      vat_status: data?.vat_status,
      addition_amount: data?.addition_amount,
      discount: data?.discount,
      web_address: data?.web_address,
      status: data?.status,
      sale_by: data?.sale_by,
      billing_type: data?.billing_type,
    };
    const response = await api(localStorage.getItem("token")).post(
      "/client-management/client/create",
      { ...obj }
    );
    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Created successfully",
      });

      dispatch({ type: LOADED });
      return response.status;
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const updateClient = (data, remark) => async (dispatch) => {
  dispatch({ type: LOADEDING });

  
  try {
    
    const obj = {
      name: data?.name,
      client: data?.id,
      address: data?.address,
      description: data?.description,
      district: data?.district,
      area: data?.area,
      contact_person: [
        {
          name: data?.name1,
          number: data?.number1,
          email: data?.email1,
          description: data?.description1,
        },
        {
          name: data?.name2,
          number: data?.number2,
          email: data?.email2,
          description: data?.description2,
        },
      ],
      email: data?.email,
      business_type: data?.business_type,
      invoice_type: data?.invoice_type,
      bill_collection_by: data?.bill_collection_by,
      vat_status: data?.vat_status,
      addition_amount: data?.addition_amount,
      discount: data?.discount,
      web_address: data?.web_address,
      status: data?.status,
      sale_by: data?.sale_by,
      billing_type: data?.billing_type,
      remark: data?.remark,
    };
    const response = await api(localStorage.getItem("token")).patch(
      "/client-management/client/update",
      { ...obj }
    );
    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Updated successfully",
      });

      dispatch({ type: LOADED });
      return response.status;
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const deleteClient = (client, data) => async (dispatch) => {
  dispatch({ type: LOADEDING });

  
  try {
    const obj = {
      client: client,
      remark: data?.remark,
    };
    const response = await api(localStorage.getItem("token")).patch(
      "/client-management/client/delete",
      { ...obj }
    );
    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Deleted successfully",
      });

      dispatch({ type: LOADED });
      return response.status;
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const helper = () => async (dispatch) => {
  dispatch({ type: LOADEDING });
  
  try {
    let response = null;
    response = await api(localStorage.getItem("token")).get(
      `/client-management/helper`
    );
    
    if (response.status === 200) {
      
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const helperDetails = (pop) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  console.log(pop)
  try {
    let response = null;
    response = await api(localStorage.getItem("token")).get(
      `/client-management/helper/pop/detail?pop=${pop}`
    );
    
    if (response.status === 200) {
      
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    /* =============Start========== */
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push("/opps"),
    });
    dispatch({ type: LOADED });
    /* ===========End============*/
  }
};

export const clientReportDownloader = (p, id, n, s, d) => async (dispatch) => {
  dispatch({ type: LOADEDING });
  
  try{
      const response = await api(localStorage.getItem("token")).get(`/client-management/client?p=${p}&id=${id ? id : ""}&n=${n ? n : ""}&s=${s ? s : ""}&d=${true}`,
        {
          headers: {
            'authorization': `Bearer ${localStorage.getItem("token")}`
            },
            method: 'GET',
            responseType: 'blob',
          }
        );
        
    if(response.status === 200 ) {
      // FileDown
      dispatch({ type: LOADED });
      const url = window.URL.createObjectURL(new Blob([response.data]),);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `client.xlsx`,
      );
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
  }
  catch( err ){
      if(err?.response?.status === 451)
        {
          history.push('/pending-verification')
        }
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        
        if(err.message == 'Network Error')
        {
            history.push('/notFound')
            return
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/404') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}