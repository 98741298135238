import React, { Component } from 'react'
import { Form, Input, Button, Checkbox, PageHeader, Select, Breadcrumb,Modal, Divider } from 'antd';
import CommonForm, { renderDate, renderInput, renderSelect, renderTextField } from '../../../component/form/form';

import { connect } from 'react-redux';
import { getRoles } from "../../../actions/role";
import { createUser, userHelper } from "../../../actions/user";
import { useEffect, useState} from 'react';
const { Option } = Select;
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const gender = [
    {value: 'male', label: 'Male'},
    {value: 'female', label: 'Female'},
    {value: 'other', label: 'Other'},
  ]
  const status = [
    {value: true, label: 'Active'},
    {value: false, label: 'Inactive'},
  ]
  

  
const ModalForm = ({visible, setVisible, getRoles, createUser, refresh, userHelper}) => {
      const [roles, setRoles] = useState(null)
      const [department, setDepartment] = useState(null)
      useEffect(async() => {
        const res = await getRoles();
        setRoles(res);
        const helper = await userHelper();
        helper?.department.push({'name': 'all'})
        setDepartment(helper?.department);
      }, [])
      const createRoles = (label,value) => {
        return {label,value}
      }
      const onFinish =async (values) => {
        // console.log('Success:', values);
        const code = await createUser(values)
        if(code === 200){
          refresh()
          setVisible()
        }
      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
      let option = [];
      option = (
        roles ? roles.map(item => 
          createRoles(item.name , item.role_id ))
      : null)

      let deptOption = [];
      deptOption = (
        department ? department.map(item => 
          createRoles(item?.name , item?.name ))
      : null)

    return ( 
        <Modal
          title="Create User"
          centered
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          width={1000}
        >
              <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
            
                {/* {renderInput('user_id', 'User ID', [
                    { required: true, message: 'This field is required!' },
                  ])
                } */}
                {renderInput('name', 'Full Name', [
                    { required: true, message: 'This field is required!' },
                  ])
                }
                {renderInput('email', 'Email', [
                    { required: true, message: 'This field is required!' },
                  ])
                }
                {renderInput('mobile_number', 'Mobile', [
                    { required: true, message: 'This field is required!' },
                    { pattern: /^(01)[0-9][0-9]{8}$/, message: 'Please valid number!' }
                  ])
                }
                {renderInput('password', 'Password', [
                    { required: true, message: 'This field is required!' },
                    { pattern: /^[A-Za-z\d@$!%*?&]{8,50}$/, message: 'Minimum 8 character, allowed special characters are @$!%*?&' }
                  ])
                }
                {renderSelect('department', 'Department', [
                    { required: true},
                  ],
                  'Select a option', deptOption, 'none')
                }
                {renderSelect('authenticated', 'Status', [
                    { required: true},
                  ],
                  'Select a option', status, 'none')
                }
                {renderSelect('role', 'Role', [
                    { required: true},
                  ],
                  'Select a option', option, 'none')
                }
                {/* <Divider orientation="center">Personal Information</Divider>

                {renderDate('date_of_birth', 'Date of Birth', [
                    { required: true, message: 'Please input your address' },
                  ])
                }
                {renderSelect('gender', 'Gender', [
                    { required: true},
                  ],
                  'Select a option and change input text above', gender, 'none')
                }
                {renderTextField('permanent_address', 'Permanent Address', [
                    { required: true, message: 'This field is required!' },
                  ])
                }
                {renderTextField('present_address', 'Present Address', [
                    { required: true, message: 'This field is required!' },
                  ])
                }
                
                {renderInput('emergency_number', 'Emergency Number', [
                    { required: true, message: 'This field is required!' },
                    { pattern: /^(01)[0-9][0-9]{8}$/, message: 'Please valid phone number!' }
                  ])
                } */}
                
                <Form.Item {...tailLayout}> 
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              
              </Form>

        </Modal>
     );
}
 
export default connect(null,{getRoles,createUser, userHelper})(ModalForm);
