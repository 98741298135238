import axios from 'axios';

export default (token = false) => {
    
    return axios.create({
        // baseURL: 'https://staging.bahonexpress.com/getco/sailor/v1/',
        baseURL: 'https://api.getcocrm.hidayahsmart.solutions/getco/sailor/v1/',
        headers: {
                'content-type': 'application/json',
                'authorization': `Bearer ${token}`
        }
    })
}