import React, { useEffect, useRef } from "react";
import {
  Descriptions,
  Tag,
  Empty,
  Timeline,
  Button,
} from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import { Title, Wrapper } from "./detailStyle";
import { Content } from "antd/lib/layout/layout";
import { Collapse } from 'antd';
import ReactToPrint from 'react-to-print';
import { ServiceToprint } from "./serviceToPrint";

const { Panel } = Collapse;

const ClientServiceDetails = ({ data  }) => {
  const componentRef = useRef();

  const makeLocalTime = (date) => {
    let hours = date.split("T")[1].split(":")[0];
    let minutes = date.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours == 12) {
      am = false;
    } else if (hours == 0) {
      hours = 12;
    }
    return (
      date.split("T")[0] +
      "," +
      hours +
      ":" +
      minutes +
      " " +
      (am ? "A.M" : "P.M")
    );
  };
  const content = (item) => {
    return <div>
    <p style={{ margin: '2px' }}>Unit Data: {item?.unit_data}</p>
    <p style={{ margin: '2px' }}>Conatct Amount: {item?.contact_amount}</p>
    <p style={{ margin: '2px' }}>Rate Amount: {item?.rate_amount}</p>
    <p style={{ margin: '2px' }}>Vat Include: {item?.vat_include ? 'Vat included' : 'Not Included'}</p>
    <p style={{ margin: '2px' }}>Vat Amount: {item?.vat_amount}</p>
    <p style={{ margin: '2px' }}>Vat Rate: {item?.vat_rate}</p>
    <p style={{ margin: '2px' }}>Internate: {item?.internet}</p>
    <p style={{ margin: '2px' }}>Youtube: {item?.youtube}</p>
    <p style={{ margin: '2px' }}>Facebook: {item?.facebook}</p>
    <p style={{ margin: '2px' }}>BDIX: {item?.bdix}</p>
    <p style={{ margin: '2px' }}>Other: {item?.other}</p>
    {/* <p style={{ margin: '2px' }}>Notes: {item?.other_band_name}</p> */}
  </div>
    
  }

  const capitalizer = s => 
    s.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')

    
  return (
    <Content style={{ margin: "0 16px", }}>
      <div style={{}}>
        <ReactToPrint
          trigger={() => <Button type="primary">Print this out!</Button>}
          content={() => componentRef.current}
        />
      </div>
        <ServiceToprint data={data} ref={componentRef} />
      {/* <Remarks
        title=" "
        btn="Submit"
        placement="bottom"
        visible={remark}
        fnc={() => setRemark(false)}
        id={[data?.email, auth]}
        delFnc={updateClientAuth}
        refresh={handleRefresh}
      /> */}
    </Content>
  );
  
};

export default connect(null, { })(
  ClientServiceDetails
);
