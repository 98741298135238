import React, { Component } from "react";
//import 'antd/dist/antd.css';
import {
  Table,
  Input,
  Button,
  Space,
  PageHeader,
  Popconfirm,
  Breadcrumb,
  Tag,
  Drawer,
  Modal,
  Form,
  Pagination,
  Row,
  Col,
  Tooltip,
  Select,
} from "antd";
import Highlighter from "react-highlight-words";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusCircleFilled,
  SearchOutlined,
  SettingOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import { processingList, updateTicket } from "../../actions/support";
import { Link } from "react-router-dom";
import history from "../../history";
import { connect } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import {
  renderInput,
  renderSelect,
  renderTextField,
} from "../../component/form/form";
import { getPops } from "../../actions/pop";
import ReactTimeAgo from "react-time-ago";
import TimeSince from "./timeSince";
import axios from "axios";
import apis from "../../actions/apis";
// import { renderTextField } from '../../../component/form/form';
// import Remarks from '../../../component/remark';
// import ModalForm from './modalForm';
// import EditModalForm from './editModalForm';
const Search = Input.Search;
const { Option } = Select;

const ProcessingTicket = ({
  processingList,
  updateTicket,
  getProcessing,
  data,
  todayData,
  getSupportDatas,
  getTransmissionData,
}) => {
  const [ID, setID] = useState("");
  const [ticketData, setTicketData] = useState([]);
  const [rId, setRId] = useState("");
  const [popId, setPopId] = useState("");
  const [page, setPage] = useState(1);
  const [name, setName] = useState("");
  const [use, setUse] = useState("");
  const [allPops, setallPops] = useState(null);
  // const [data, setData] = useState(null)
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [access, setAccess] = useState("");
  const [form] = Form.useForm();
  const [cform] = Form.useForm();

  const createOption = (value, label) => {
    return { value, label };
  };

  useEffect(() => {
    getProcessing();
    setAccess(JSON.parse(localStorage.getItem("access"))?.ticket_management);
  }, []);

  const getUpdateData = async (id) => {
    try {
      const response = await apis(localStorage.getItem("token")).get(
        `ticket-management/ticket/detail?ticket=${id}`
      );
      setTicketData(response.data);
    } catch (error) {
      console.error("Error fetching ticket data:", error);
    }
  };
  const showModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleOk = () => {
    setIsCreateModalOpen(false);
  };

  const handleCancel = () => {
    setIsCreateModalOpen(false);
  };

  const handleUpdate = (id) => {
    getUpdateData(id);
    console.log("ticketData", ticketData);
    setID(id);
    setIsCreateModalOpen(true);
    cform.setFieldsValue({
      ticket: id,
    });
  };

  const handleView = async (id) => {
    history.push(`/ticket/details?id=${id}`);
    // const res =  await deleteRouter(item?.id, 'client-type');
    // if(res === 201){
    //   getProcessing();
    // };
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Search
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const makeLocalTime = (date) => {
    let mainDate = date.split("T")[0];
    let hours = date.split("T")[1].split(":")[0];
    let minutes = date.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours == 12) {
      am = false;
    } else if (hours == 0) {
      hours = 12;
    }
    return (
      mainDate + " , " + hours + ":" + minutes + " " + (am ? "a.m" : "p.m")
    );
  };

  const createData = (
    id,
    clients,
    branch,
    problem_type,
    date,
    down_time,
    create_by,
    claimed_by,
    action
  ) => {
    return {
      id,
      clients,
      branch,
      problem_type,
      date,
      down_time,
      create_by,
      claimed_by,
      action,
    };
  };
  const columns = [
    {
      title: "Ticket no",
      dataIndex: "id",
      key: "id",
      width: "10%",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Clients/POP",
      dataIndex: "clients",
      key: "clients",
      width: "20%",
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
      width: "20%",
    },
    {
      title: "Problem type",
      dataIndex: "problem_type",
      key: "problem_type",
      width: "20%",
      ...getColumnSearchProps("problem_type"),
    },
    {
      title: "Date Time",
      dataIndex: "date",
      key: "date",
      width: "20%",
    },
    {
      title: "Down Since	",
      dataIndex: "down_time",
      key: "down_time",
      width: "20%",
    },
    {
      title: "Created By",
      dataIndex: "create_by",
      key: "create_by",
      width: "20%",
      ...getColumnSearchProps("create_by"),
    },
    {
      title: "Claimed by",
      dataIndex: "claimed_by",
      key: "claimed_by",
      width: "20%",
      ...getColumnSearchProps("claimed_by"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "15%",
    },
  ];
  const rows = [
    data
      ? data?.list
          ?.slice(0)
          .reverse()
          ?.map((item, key) =>
            createData(
              item?.id,
              <>
                <p style={{ margin: "0px" }}>
                  {item?.client?.name
                    ? item?.client?.name
                    : "POP: " + item?.pop?.name}
                </p>
              </>,
              item?.sub_client?.name ? (
                item?.sub_client?.name
              ) : (
                <>
                  <p style={{ margin: "0px" }}>
                    {item?.router?.name
                      ? "Router: " + item?.router?.name
                      : null}
                  </p>
                  <p style={{ margin: "0px" }}>
                    {item?.switch?.name
                      ? "Switch: " + item?.switch?.name
                      : null}
                  </p>
                  <p style={{ margin: "0px" }}>
                    {item?.switch_port_no || item?.switch_port_no === 0
                      ? "Switch Port: " + item?.switch_port_no
                      : null}
                  </p>
                </>
              ),
              item?.problem_type,
              item?.created_at ? makeLocalTime(item?.created_at) : null,
              // item?.created_at ? <ReactTimeAgo date={item?.created_at} locale="en-US"/> : null,
              item?.created_at ? (
                <TimeSince
                  status={item?.status}
                  date={(item?.created_at).split(".")[0]}
                />
              ) : null,
              item?.creator?.name,
              item?.hold_by?.name,
              <>
                {access === "a" ? (
                  <>
                    <Button
                      disabled={!item?.processable}
                      size="small"
                      type="primary"
                      onClick={() => handleUpdate(item?.id)}
                      title="Edit"
                      icon={<EditOutlined />}
                    ></Button>{" "}
                    &nbsp;
                    <Button
                      size="small"
                      type="primary"
                      icon={<EyeOutlined />}
                      onClick={() => handleView(item?.id)}
                      title="View"
                    ></Button>{" "}
                    &nbsp;
                  </>
                ) : null}
                {access === "w" ? (
                  <>
                    <Button
                      disabled={!item?.processable}
                      size="small"
                      type="primary"
                      onClick={() => handleUpdate(item?.id)}
                      title="Edit"
                      icon={<EditOutlined />}
                    ></Button>{" "}
                    &nbsp;
                    <Button
                      size="small"
                      type="primary"
                      icon={<EyeOutlined />}
                      onClick={() => handleView(item?.id)}
                      title="View"
                    ></Button>{" "}
                    &nbsp;
                  </>
                ) : null}
                {access === "r" ? (
                  <>
                    <Button
                      size="small"
                      type="primary"
                      icon={<EyeOutlined />}
                      onClick={() => handleView(item?.id)}
                    >
                      View
                    </Button>{" "}
                    &nbsp;
                  </>
                ) : null}
                {/* <Button size="small" type="danger" icon={<DeleteOutlined  />} onClick={() => handleDelete(item)}>Delete</Button> &nbsp; */}
              </>
            )
          )
      : "",
  ];

  const onFinish = async (values) => {
    const res = await updateTicket(values);
    if (res === 201) {
      todayData();
      getProcessing();
      getSupportDatas();
      getTransmissionData();
      setIsCreateModalOpen(false);
      cform.resetFields();
    }
  };

  const onFinishEdit = async (values) => {
    // const res = await updateRouter(values);
    // if(res ===  201){
    //   getProcessing();
    //   setIsEditModalOpen(false);
    // }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  // const handlePagination = async (e) => {
  //   setPage(e);
  //   const res = await getRouters(e, rId, name, use, popId)
  //   setData(res);
  // }

  const status = [
    { value: "release for other", label: "Release for other" },
    { value: "comment", label: "Comment" },
    { value: "half done", label: "Half Done" },
    { value: "completed", label: "Completed" },
  ];

  return (
    <Content style={{ margin: "0 16px" }}>
      <Table
        size="small"
        columns={columns}
        pagination={false}
        dataSource={rows[0]}
        scroll={{ x: 1000, y: 450 }}
        sticky
      />
      <br />

      {/* <Pagination defaultCurrent={1} pageSize="20" total={data?.count} onChange={(e) => { handlePagination(e); }} /> */}

      <Modal
        title="Update"
        open={isCreateModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        footer={null}
      >
        <Row gutter={16}>
          <Col
            xs={18}
            lg={18}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              marginBottom: "2em",
              marginInline: "5em",
            }}
          >
            {ticketData?.detail?.problem_type && (
              <>
                <span>
                  <b>Problem Type: </b>
                </span>
                <Tag>{ticketData?.detail?.problem_type}</Tag>
              </>
            )}

            {ticketData?.detail?.sub_client?.name && (
              <>
                <span>
                  <b>Branch: </b>
                </span>
                <Tag>{ticketData?.detail?.sub_client?.name}</Tag>{" "}
              </>
            )}
            {ticketData?.detail?.client?.name && (
              <>
                <span>
                  <b>Client/POP: </b>
                </span>
                <Tag>{ticketData?.detail?.client?.name}</Tag>
              </>
            )}
          </Col>
          <br />
          <Col xs={18} lg={18}>
            <Form
              {...layout}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              form={cform}
            >
              <Form.Item label="Ticket Id" name="ticket">
                <Input size="large" name="ticket" disabled />
              </Form.Item>

              {renderSelect(
                "status",
                "Status",
                [{ required: true, message: "This field is required!" }],
                " ",
                status,
                "",
                "large"
              )}
              {renderTextField(
                "remark",
                "Remark",
                [{ required: true, message: "This field is required!" }],
                "small"
              )}

              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </Content>
  );
};

export default connect(null, { processingList, updateTicket })(
  ProcessingTicket
);
