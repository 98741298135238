import React, { Component } from 'react'
//import 'antd/dist/antd.css';
import { Table, Input, Button, Space, PageHeader, Popconfirm, Select, Breadcrumb,Tag, Drawer, Modal, Form, Pagination, Row, Col, Tooltip    } from 'antd';
import Highlighter from 'react-highlight-words';
import { CheckCircleOutlined, DeleteOutlined, EditOutlined, EyeOutlined, PlusCircleFilled, SearchOutlined,SettingOutlined,StopOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { createTransmissionTicket, transmissionHelper, transmissionHelperById, ticketHelper } from "../../actions/support";
import { Link } from 'react-router-dom';
import history from '../../history';
import { connect } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { renderInput, renderSelect, renderTextField } from '../../component/form/form';
// import { renderTextField } from '../../../component/form/form';
// import Remarks from '../../../component/remark';
// import ModalForm from './modalForm';
// import EditModalForm from './editModalForm';
const Search = Input.Search;
const { Option } = Select;

const CreateTransmssion = ({ createTransmissionTicket, transmissionHelper, transmissionHelperById, ticketHelper }) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [allRouters, setAllRouters] = useState([])
  const [allSwitch, setAllSwitch] = useState([])
  const [pops, setPops] = useState([]);
  const [problems, setProblems] = useState([]);
  const [department, setDepartment] = useState(null)
  const [statusVal, setStatusVal] = useState(null)
  const [form] = Form.useForm();
  const [cform] = Form.useForm();
  
  const createOption = (value, label) => {
    return {value, label};
  };

  useEffect( async () => {
    const helper = await ticketHelper();
    helper?.department.push({'name': 'all'})
    setDepartment(helper?.department);

    const res = await transmissionHelper();
    
    const popoption = [... 
      res?.pop_list?.map((item, key) => createOption(
        item.name + ',-,' + item.id, 
      item.name,
      ))
    ]
    const poption = [... 
      res?.problem_list?.map((item, key) => createOption(
      item.name, 
      item.name,
      ))
    ]

    setPops(popoption);
    setProblems(poption);
    
  }, [])
  

  const status = [
    {value: 'active', label: 'Active'},
    {value: 'half done', label: 'Half Done'},
    {value: 'completed', label: 'Completed'},
  ]

  const priority = [
    {value: 'normal', label: 'Normal'},
    {value: 'medium', label: 'Medium'},
    {value: 'high', label: 'High'},
  ]

  const handlePopSelect = async (e) => {
    if(e){
      const res =  await transmissionHelperById(e.split(',-,')[1]);
      if(res){
        const route = [... 
          res?.router_list?.map((item, key) => createOption(
          item.name + ',-,' + item?.id, 
          item?.name + ' -- ' + item?.id,
          ))
        ]
        const switc = [... 
          res?.switch_list?.map((item, key) => createOption(
          item.name + ',-,' + item?.id, 
          item?.name + ' -- ' + item?.id,
          ))
        ]
        setAllRouters(route)
        setAllSwitch(switc)
      }
      
    }
  };

  const showModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleOk = () => {
    setIsCreateModalOpen(false);
  };

  const handleCancel = () => {
    setIsCreateModalOpen(false);
  };


  let deptOption = [];
  deptOption = (
    department ? department.map(item => 
      createOption(item?.name , item?.name ))
  : null)

  const onFinish = async (values) => {
    const res = await createTransmissionTicket(values);
    if(res ===  201){
      cform.resetFields();
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };
  


  return ( 
    <Content style={{ margin: '0 16px' }}>

      <Breadcrumb style={{padding:"10px 0px"}}>
        <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Create Ticket POP</Breadcrumb.Item>
      </Breadcrumb>

      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Create Ticket POP"
        subTitle="Ticket Management"
        // extra={[
        //   <Button key="1"  type="primary" icon={<PlusCircleFilled />} onClick = { showModal}>Create New
        //   </Button>,
        // ]}
      >
      </PageHeader>
      
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={cform}
        >
          <Form.Item
            name="pop"
            label="POP"
            rules={[{ required: true, message: 'This field is required!' }]}
          >
            <Select
              showSearch
              placeholder="Select a pop"
              size="large"
              allowClear
              onChange={(e) => handlePopSelect(e)}
            >
                {pops?.map(item => (
                    <Option value={item.value}>{item.label}</Option>
                ))}
            </Select>
          </Form.Item> 
          {allRouters.length > 0 ? renderSelect('router', 'Router', [
            // { required: true, message: 'This field is required!' },
            ], "", allRouters, 'single', 'large')
            : null
          }
                
          { 
            (allRouters.length > 0 && allSwitch.length > 0) ?
              renderSelect('switch', 'Switch', [
                // { required: true, message: 'This field is required!' },
                ], "", allSwitch, 'single', 'large')
            : null
          }
          
  
          {renderInput('switch_port_no', 'Switch Port', [
            { required: false, message: 'This field is required!' },
          ], 'large')
          }
          {deptOption ?
           renderSelect('department', 'Department', [
              { required: true},
            ],
            'Select a department', deptOption, 'none')
          : null}
          
          {renderSelect('problem_type', 'Problem type', [
              { required: true, message: 'This field is required!' },
            ],' ', problems, )
          }
          {renderSelect('priority', 'Priority', [
              { required: true, message: 'This field is required!' },
            ],' ', priority)
          }
          {renderInput('subject', 'Subject', [
            { required: true, message: 'This field is required!' },
          ], 'large')
          }
          {/* {renderSelect('pop', 'Pop', [
              { required: true, message: 'This field is required!' },
            ],' ', pops)
          } */}
          {/* {renderSelect('sub_client', 'Subclient', [
              { required: true, message: 'This field is required!' },
            ],' ', clients)
          } */}
          {renderTextField('description', 'Description', [
              { required: true, message: 'This field is required!' },
            ], 'large')
          }
          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: false, message: 'This field is required!' }]}
          >
            <Select
              showSearch
              placeholder="Select Stattus"
              size="large"
              allowClear
              onChange={(e) => setStatusVal(e)}
            >
                {status?.map(item => (
                    <Option value={item.value}>{item.label}</Option>
                ))}
            </Select>
          </Form.Item> 
          
          {renderTextField('remark', 'Solution', [
              { required: statusVal === 'completed' ? true : false, message: 'This field is required!' },
              ], 'small')
          }
           
          <Form.Item {...tailLayout}> 
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
                
        </Form>
      
    </Content>
   );
}
 
export default connect(null, { createTransmissionTicket, transmissionHelper, transmissionHelperById, ticketHelper })(CreateTransmssion);